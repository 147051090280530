import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 300,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    inputBox: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
    },
    input: {
      width: 120,
      height: 36,
      lineHeight: '34px',
      padding: '0 5px',
      fontSize: 14,
      color: colorVariables.grey60,
      textAlign: 'center',
      fontWeight: 600,
      border: 0,
      outline: 'none',
      borderRadius: 5,
      boxShadow: `inset 0 0 0 1px ${colorVariables.grey60}`,
      '&:focus': {
        boxShadow: `inset 0 0 0 1px ${colorVariables.green}`,
      },
      '&::placeholder': {
        color: colorVariables.steelGrey,
      },
    },
    inputGreen: {
      boxShadow: `inset 0 0 0 1px ${colorVariables.green}`,
      '&:focus': {
        boxShadow: `inset 0 0 0 1px ${colorVariables.green}`,
      },
    },
    inputRed: {
      boxShadow: `inset 0 0 0 1px #EA6363`,
      '&:focus': {
        boxShadow: `inset 0 0 0 1px #EA6363`,
      },
    },
    container: {
      width: 300,
      padding: 10,
      paddingTop: 25,
      position: 'relative',
    },
    calendar: {
      fontSize: 14,
      '& .rdrMonth': {
        width: 276,
      },
      '& .rdrMonthAndYearPickers': {
        color: colorVariables.navy,
        fontWeight: 700,
        fontSize: 16,
      },
      '& .rdrWeekDays': {
        borderBottom: `1px solid ${colorVariables.steelGrey}`,
      },
      '& .rdrWeekDay': {
        color: colorVariables.grey60,
        fontWeight: 700,
      },
      '& .rdrDay': {
        height: 46,
        flexShrink: 0,
      },
      '& .rdrDayNumber span': {
        color: colorVariables.grey80,
      },
      '& .rdrDayDisabled span': {
        color: colorVariables.grey40,
      },
      '& .rdrDateDisplayWrapper': {
        backgroundColor: colorVariables.transparent,
      },
      '& .rdrNextPrevButton': {
        width: 26,
        height: 26,
        borderRadius: 13,
      },
      '& .rdrNextButton': {
        position: 'relative',
        '&::after': {
          content: '">"',
          fontSize: 18,
          width: 26,
          lineHeight: '26px',
          height: 26,
          display: 'block',
          textAlign: 'center',
          color: colorVariables.grey60,
          fontWeight: 700,
        },
        '& i': {
          display: 'none',
        },
      },
      '& .rdrPprevButton': {
        position: 'relative',
        '&::after': {
          content: '"<"',
          fontSize: 18,
          width: 26,
          lineHeight: '26px',
          height: 26,
          display: 'block',
          textAlign: 'center',
          color: colorVariables.grey60,
          fontWeight: 700,
        },
        '& i': {
          display: 'none',
        },
      },
      '& .rdrStartEdge': {
        '& ~ .rdrDayNumber': {
          backgroundColor: colorVariables.green,
          borderRadius: '50%',
          zIndex: 5,
          position: 'absolute',
        },
      },
      '& .rdrEndEdge': {
        '& ~ .rdrDayNumber': {
          backgroundColor: colorVariables.green,
          borderRadius: '50%',
          zIndex: 5,
          position: 'absolute',
        },
      },
      '& .rdrStartEdge ~ .rdrDayNumber': {
        fontWeight: 700,
        color: colorVariables.white,
      },
      '& .rdrEndEdge ~ .rdrDayNumber': {
        fontWeight: 700,
        color: colorVariables.white,
      },
      '& .rdrDayPassive .rdrStartEdge ~ .rdrDayNumber': {
        backgroundColor: 'transparent',
      },
      '& .rdrDayPassive .rdrEndEdge ~ .rdrDayNumber': {
        backgroundColor: 'transparent',
      },

      '& .rdrInRange ~ .rdrDayNumber span': {
        color: `${colorVariables.navy} !important`,
        fontWeight: 700,
      },
      '& .rdrDayPassive .rdrDayNumber span': {
        color: 'transparent !important',
      },

      '& .rdrDayToday .rdrDayNumber span:after': {
        backgroundColor: colorVariables.navy,
      },
      '& .rdrDayPassive.rdrDayToday .rdrDayNumber span:after': {
        display: 'none',
      },
      '& .rdrDayDisabled': {
        backgroundColor: 'transparent',
      },
      '& .rdrDateDisplayItem': {
        boxShadow: 'none',
        borderColor: colorVariables.steelGrey,
      },
      '& .rdrDateDisplayItemActive': {
        borderColor: colorVariables.green,
      },
      '& .rdrDateInput:first-child': {
        position: 'relative',
        marginTop: 30,
        '&::before': {
          position: 'absolute',
          top: -24,
          left: -5,
          content: '"From"',
          fontSize: 14,
          width: 50,
          lineHeight: '20px',
          height: 20,
          display: 'block',
          textAlign: 'center',
          color: colorVariables.navy,
          fontWeight: 400,
        },
      },
      '& .rdrDateInput:last-child': {
        position: 'relative',
        marginTop: 30,
        '&::before': {
          position: 'absolute',
          top: -24,
          left: -15,
          content: '"To"',
          fontSize: 14,
          width: 50,
          lineHeight: '20px',
          height: 20,
          display: 'block',
          textAlign: 'center',
          color: colorVariables.navy,
          fontWeight: 400,
        },
      },
    },

    calendarRed: {
      '& .rdrStartEdge': {
        backgroundColor: `rgba(240, 146, 146, 0.3) !important`,
        '& ~ .rdrDayNumber': {
          backgroundColor: '#EA6363',
          borderRadius: '50%',
          zIndex: 5,
          position: 'absolute',
        },
      },
      '& .rdrEndEdge': {
        backgroundColor: `rgba(240, 146, 146, 0.3) !important`,
        '& ~ .rdrDayNumber': {
          backgroundColor: '#EA6363',
          borderRadius: '50%',
          zIndex: 5,
          position: 'absolute',
        },
      },
      '& .rdrInRange': {
        backgroundColor: `rgba(240, 146, 146, 0.3) !important`,
      },
      '& .rdrDateDisplayItemActive': {
        borderColor: '#EA6363',
      },
    },
    inputWrap: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    toolTip: {
      display: 'block',
      width: 80,
      height: 24,
      borderRadius: 4,
      textAlign: 'center',
      fontSize: 11,
      lineHeight: '24px',
      padding: '0 5px',
      color: colorVariables.white,
      backgroundColor: colorVariables.navy,
      position: 'absolute',
      top: -25,
      left: 0,

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -15,
        left: 24,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: `${colorVariables.navy} transparent transparent transparent`,
      },
    },
    label: {
      fontSize: 14,
      marginBottom: 5,
    },
    btnBoxWrap: {
      marginTop: -20,
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    triggerBox: {
      width: '100%',
    },
    maxDaysWarning: {
      display: 'grid',
      gridTemplateColumns: '20px auto',
      padding: '0 20px',
      color: '#5B6578',
      opacity: 0.7,
      fontSize: 12,
      marginBottom: 15,
      '& svg': {
        marginTop: 2,
      },
    },
    maxDaysWarningRed: {
      color: '#E63030',
    },
  }),
);
