import { createStyles, makeStyles } from '@material-ui/core';
import { Sizes } from '../../../../styles/sizes';
import { colorVariables } from '../../../../styles/colorVariables';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 20,
      paddingBottom: 60,
    },
    typeScreen: {
      backgroundColor: 'rgba(152, 165, 183, 0.15)',
      marginTop: -25,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginTop: 0,
        boxShadow: '0 0 0 20px rgba(152, 165, 183, 0.15)',
      },
    },
    title: {
      fontSize: 28,
      fontWeight: 700,
      marginBottom: 12,
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 16,
      },
    },
    subtitle: {
      opacity: 0.7,
      marginBottom: 20,
      textAlign: 'center',
      [theme.breakpoints.down(Sizes.mobile)]: {
        '& br': {
          display: 'none',
        },
      },
    },
    supplierPageTitle: {
      fontFamily: 'Sofia Pro',
      paddingTop: 40,
      marginBottom: 25,
      [theme.breakpoints.down(Sizes.tablet)]: {
        marginBottom: 15,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        fontSize: 24,
        paddingTop: 0,
        textAlign: 'center',
        lineHeight: '30px',
        '& br': {
          display: 'none',
        },
      },
    },
    supplierTypeWrap: {
      paddingTop: 23,
      display: 'grid',
      position: 'relative',
      marginBottom: 32,
      gridTemplateColumns: '147px 56px 147px',
      '&>div:nth-child(2)': {
        fontWeight: 700,
        fontSize: 20,
        textAlign: 'center',
        lineHeight: '188px',
        [theme.breakpoints.down(Sizes.mobile)]: {
          lineHeight: '138px',
        },
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        gridTemplateColumns: '130px 46px 130px',
      },
    },
    supplierTypeTile: {
      paddingTop: 36,
      height: 188,
      backgroundColor: colorVariables.white,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#4BCFBB',
      border: '2px solid #4BCFBB',
      borderRadius: 10,
      cursor: 'pointer',
      '&>span': {
        userSelect: 'none',
        paddingTop: 16,
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center',
        lineHeight: '21px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        paddingTop: 10,
        height: 138,
        '&>svg': {
          scale: 0.7,
        },
        '&>span': {
          paddingTop: 0,
          fontSize: 16,
        },
      },
    },
    supplierTypeTileActive: {
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
      backgroundColor: colorVariables.greenLight,
    },
    recWrap: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    recommendedLabel: {
      background: 'linear-gradient(90deg, #51D9B0 0%, #33A8E2 100% )',
      color: colorVariables.white,
      fontSize: 10,
      fontWeight: 600,
      width: 95,
      height: 17,
      lineHeight: '15px',
      textAlign: 'center',
      borderRadius: '0 0 10px 10px',
    },
    supplierTypeText: {
      maxWidth: 396,
      lineHeight: '24px',
      textAlign: 'center',
      opacity: 0.7,
      marginBottom: 40,
    },
    selectedSupplierType: {
      backgroundColor: '#4BCFBB',
      '& g': {
        fill: '#fff',
      },
      '& span': {
        color: '#fff',
      }
    }
  }),
);
