import * as React from 'react';

function SvgInfoIconCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 1.168a5.835 5.835 0 00-5.834 5.833 5.835 5.835 0 005.833 5.834A5.835 5.835 0 0012.833 7a5.836 5.836 0 00-5.834-5.833zm-.584 8.75v-3.5h1.167v3.5H6.416zm0-5.833V5.25h1.167V4.085H6.416z'
        fill='#000'
      />
      <mask
        id='InfoIconCalendar_svg__a'
        style={{
          maskType: 'luminance',
        }}
        maskUnits='userSpaceOnUse'
        x={1}
        y={1}
        width={12}
        height={12}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7 1.168a5.835 5.835 0 00-5.834 5.833 5.835 5.835 0 005.833 5.834A5.835 5.835 0 0012.833 7a5.836 5.836 0 00-5.834-5.833zm-.584 8.75v-3.5h1.167v3.5H6.416zm0-5.833V5.25h1.167V4.085H6.416z'
          fill='#fff'
        />
      </mask>
      <g mask='url(#InfoIconCalendar_svg__a)'>
        <path fill={props.color || '#98A5B7'} d='M0 0h14v14H0z' />
      </g>
    </svg>
  );
}

export default SvgInfoIconCalendar;
