import { createReducer } from '@reduxjs/toolkit';
import { logoutThunk } from '../user';
import { SSInventorySortBy } from '../../api/supplier-inventory/types';
import { setSupplierInventoryKeyword, setSupplierInventoryPage, setSupplierInventorySort, setSupplierInventorySubCategory } from './supplier-inventory.actions';

interface State {
  sortBy?: SSInventorySortBy;
  keyword?: string;
  sub_cat?: number;
  page: number;
}

const initialState: State = {
  sortBy: undefined,
  keyword: undefined,
  sub_cat: undefined,
  page: 1,
};

export const supplierInventoryReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return { ...initialState, filterBy: undefined };
  });
  builder.addCase(setSupplierInventoryKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.page = 1;
  });
  builder.addCase(setSupplierInventorySort, (state, { payload }) => {
    state.sortBy = payload;
  });
  builder.addCase(setSupplierInventorySubCategory, (state, { payload }) => {
    state.sub_cat = payload;
    state.page = 1;
  });
  builder.addCase(setSupplierInventoryPage, (state, { payload }) => {
    state.page = payload;
  });
});
