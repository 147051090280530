import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Done } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import LinesEllipsis from 'react-lines-ellipsis';
import { CommonButton } from '../../../../../shared/components/common-button';
import { useGetConnectionRequestsQuery } from '../../../../../api/company';
import { CustomerConnectionStatus } from '../../../../../api/customer/types';
import { ManualSupplierAction } from '../../../../../api/supplier/types';
import { useGeIsTeammateViewer } from '../../../../../api/teammates/hooks';
import { SupplierUser } from '../../../../../api/user/types';
import { AddButton } from '../../../../../shared/components/add-button';
import { BlurredImage } from '../../../../../shared/components/blurred-image';
import { DropdownMenu } from '../../../../../shared/components/dropdown-menu';
import { useScreenSize } from '../../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setProductsFilterBy } from '../../../../../store/products';
import { setManualSupplierAction } from '../../../../../store/suppliers-categories';
import { setSelectedSupplier } from '../../../../../store/suppliers-categories/suppliers-categories.actions';
import { getSelectedSupplier } from '../../../../../store/suppliers-categories/suppliers-categories.selectors';

import { useStyles } from './style';

interface Props {
  supplier: SupplierUser;
  handleClick: (event: React.MouseEvent<any>) => void;
  isOnBoarding?: boolean;
  productsAvailable?: boolean;
  isSuppliersListPage?: boolean;
}

const Component: React.FC<Props> = ({ supplier, handleClick, isOnBoarding, productsAvailable, isSuppliersListPage }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { isMobile } = useScreenSize();
  const isViewer = useGeIsTeammateViewer('supplier_and_categories');

  const { request } = useGetConnectionRequestsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      request: data?.find((req) => req.supplier?.id === supplier.id),
    }),
  });

  const selectedSupplierId = useAppSelector(getSelectedSupplier)?.id;

  const onSupplierClick = (event: React.MouseEvent<any>) => {
    if (isViewer) {
      ToastService.error('You have been restricted from making edits.');
      return;
    }
    handleClick(event);
    dispatch(setSelectedSupplier(supplier));
  };

  const openProductsBySupplier = (supplier_id: number) => {
    push(`/browse_by_supplier/${supplier_id}/products`);
    dispatch(
      setProductsFilterBy({
        supplier_id,
      }),
    );
  };

  const onSetManualSupplier = (action: ManualSupplierAction) => {
    dispatch(setManualSupplierAction({ id: supplier.id, action }));
    if (isMobile && action !== ManualSupplierAction.UPLOAD) {
      push(`/account/supplier/${supplier.id}/products`);
    }
  };

  const onEditManualSupplier = () => {
    !supplier.locked_supplier && push(`/account/edit-manual-supplier/${supplier.id}`);
  };

  return (
    <div
      className={clsx([
        classes.supplierItem,
        selectedSupplierId === supplier.id && classes.supplierItemSelected,
        isOnBoarding && classes.supplierItemOnboarding,
      ])}
    >
      <div className={classes.supplierInfo} onClick={productsAvailable ? openProductsBySupplier.bind(null, supplier.id) : undefined}>
        <div className={classes.avatarWrap}>
          <BlurredImage src={supplier.company.picture_url || ''} />
        </div>
        <div className={classes.supplierDetailsBox}>
          <div className={classes.supplierName}>
            <LinesEllipsis text={supplier.company.name} ellipsis='...' />
          </div>
          <div className={classes.supplierAddress}>
            {supplier.company_addresses[0]?.city && supplier.company_addresses[0]?.state
              ? `${supplier.company_addresses[0].city.slice(0, 20)}, ${supplier.company_addresses[0].state.toUpperCase()}`
              : ''}
          </div>
        </div>
      </div>
      {supplier?.manual_supplier && isSuppliersListPage && (
        <div className={classes.managePricingBox}>
          <DropdownMenu
            items={
              supplier.locked_supplier
                ? [
                    <div key='Upload new Price list' onClick={onSetManualSupplier.bind(null, ManualSupplierAction.UPLOAD)}>
                      Upload new Price list
                    </div>,
                    <div key='View your Price list' onClick={onSetManualSupplier.bind(null, ManualSupplierAction.VIEW)}>
                      View your Price list
                    </div>,
                    <div key='Edit your Price lists' onClick={onSetManualSupplier.bind(null, ManualSupplierAction.EDIT)}>
                      Edit your Price lists
                    </div>,
                  ]
                : [
                    <div key='Upload new Price list' onClick={onSetManualSupplier.bind(null, ManualSupplierAction.UPLOAD)}>
                      Upload new Price list
                    </div>,
                    <div key='View your Price list' onClick={onSetManualSupplier.bind(null, ManualSupplierAction.VIEW)}>
                      View your Price list
                    </div>,
                    <div key='Edit your Price lists' onClick={onSetManualSupplier.bind(null, ManualSupplierAction.EDIT)}>
                      Edit your Price lists
                    </div>,
                    <div key='Edit supplier profile' onClick={onEditManualSupplier}>
                      Edit supplier profile
                    </div>,
                  ]
            }
            triggerBtn={
              <div className={classes.managePricingTrigger}>
                <span>Manage Pricing</span>
                <KeyboardArrowDownIcon />
              </div>
            }
          />
        </div>
      )}
      <>
        {!request && isOnBoarding && (
          <div className={classes.addBtnWrap} onClick={onSupplierClick}>
            <AddButton />
          </div>
        )}
        {request && isOnBoarding && (
          <div className={clsx(classes.addBtnWrap, classes.tickWrap)} onClick={onSupplierClick}>
            <Done className={classes.doneIcon} />
          </div>
        )}
        {!request && (
          <CommonButton
            onClick={onSupplierClick}
            title='Add supplier'
            isSmall={true}
            bordered={true}
            endIcon={<KeyboardArrowDownIcon />}
            className={clsx([classes.reqBtn, classes.reqBtnAdd, isOnBoarding && classes.reqBtnOnBoarding])}
          />
        )}
        {(request?.status === CustomerConnectionStatus.NEW_REQUEST || request?.status === CustomerConnectionStatus.PENDING) && (
          <CommonButton
            onClick={onSupplierClick}
            title='Requested'
            isSmall={true}
            buttonStyle='secondary'
            endIcon={<KeyboardArrowDownIcon />}
            className={clsx([classes.reqBtn, classes.reqBtnSecondary, isOnBoarding && classes.reqBtnOnBoarding])}
          />
        )}
        {request?.status === CustomerConnectionStatus.ACCEPTED && (
          <CommonButton
            onClick={onSupplierClick}
            title='Connected'
            isSmall={true}
            buttonStyle='primary'
            endIcon={<KeyboardArrowDownIcon />}
            className={clsx([classes.reqBtn, classes.reqBtnPrimary, isOnBoarding && classes.reqBtnOnBoarding])}
          />
        )}
      </>
    </div>
  );
};

export const SupplierItem = memo(Component);
