import { createEntityAdapter, createReducer, EntityState, isAnyOf } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';
import { inventoryApi } from '../../api/inventory';
import { Inventory, InventoryFilter, InventorySortBy } from '../../api/inventory/types';
import { logoutThunk } from '../user/user.actions';
import {
  resetInventoryList,
  setAddSubMeasureFor,
  setEditModeInventoryRow,
  setEditSubMeasureFor,
  setInventoryFilter,
  setInventoryKeyword,
  setInventoryPage,
  setInventorySort,
  setInventorySortFilter,
  setInventoryStorageKeyword,
  setReplenishLoading,
  setSelectedInventoryStorageSupplier,
  setSelectedInventorySupplier,
  setSelectedStorage,
} from './inventory.actions';
import { stockTakeApi } from '../../api/stocktake';

export const inventoryAdapter = createEntityAdapter<Inventory>();

interface State {
  sortBy?: InventorySortBy;
  filterBy?: InventoryFilter;
  keyword?: string;
  selectedStorage: null | string;
  selectedSupplier: null | number;
  list: EntityState<Inventory>;
  currentPage: number;
  storageKeyword?: string;
  storageSelectedSupplier: null | number;
  replenishLoading?: boolean;
  addSubMeasureFor: number | null;
  subMeasureId: number | null;
  editModeRow: number | null;
}

const initialState: State = {
  list: inventoryAdapter.getInitialState(),
  sortBy: undefined,
  keyword: undefined,
  selectedStorage: null,
  selectedSupplier: null,
  currentPage: 1,
  filterBy: undefined,
  replenishLoading: false,
  storageSelectedSupplier: null,
  addSubMeasureFor: null,
  subMeasureId: null,
  editModeRow: null,
};

export const inventoryReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutThunk.fulfilled, () => {
    return initialState;
  });
  builder.addCase(setInventoryPage, (state, { payload }) => {
    state.currentPage = payload;
  });
  builder.addCase(resetInventoryList, (state) => {
    state.list = inventoryAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addCase(setSelectedStorage, (state, { payload }) => {
    state.selectedStorage = payload;
  });
  builder.addCase(setInventorySort, (state, { payload }) => {
    state.sortBy = payload;
    state.currentPage = 1;
    state.list = inventoryAdapter.getInitialState();
  });
  builder.addCase(setInventorySortFilter, (state, { payload }) => {
    if (!isEqual(state.filterBy, payload.filter) || !isEqual(payload.sort, state.sortBy)) {
      state.filterBy = payload.filter;
      state.sortBy = payload.sort;
      state.currentPage = 1;
      state.list = inventoryAdapter.getInitialState();
    }
  });
  builder.addCase(setSelectedInventorySupplier, (state, { payload }) => {
    if (state.selectedSupplier === payload) {
      return;
    }
    state.selectedSupplier = payload;
    state.currentPage = 1;
    state.list = inventoryAdapter.getInitialState();
  });
  builder.addCase(setInventoryKeyword, (state, { payload }) => {
    if (state.keyword === payload) {
      return;
    }
    state.keyword = payload;
    state.currentPage = 1;
    state.list = inventoryAdapter.getInitialState();
  });
  builder.addCase(setInventoryFilter, (state, { payload }) => {
    if (!isEqual(state.filterBy, payload)) {
      state.filterBy = payload;
      state.currentPage = 1;
      state.list = inventoryAdapter.getInitialState();
    }
  });
  builder.addCase(setSelectedInventoryStorageSupplier, (state, { payload }) => {
    state.storageSelectedSupplier = payload;
  });
  builder.addCase(setAddSubMeasureFor, (state, { payload }) => {
    state.addSubMeasureFor = payload;
    state.subMeasureId = null;
  });
  builder.addCase(setEditSubMeasureFor, (state, { payload: {id, sub_measure_id} }) => {
    state.addSubMeasureFor = id;
    state.subMeasureId = sub_measure_id;
  });
  builder.addCase(setInventoryStorageKeyword, (state, { payload }) => {
    state.storageKeyword = payload;
  });
  builder.addCase(setReplenishLoading, (state, { payload }) => {
    state.replenishLoading = payload;
  });
  builder.addCase(setEditModeInventoryRow, (state, { payload }) => {
    state.editModeRow = payload;
  });
  builder.addMatcher(
    isAnyOf(
      inventoryApi.endpoints.setUpIncompletedInventory.matchFulfilled,
    ),
    (state, { payload, meta }) => {
      state.list = inventoryAdapter.upsertOne(state.list, payload.inventory_product);
    },
  );
  builder.addMatcher(
    isAnyOf(
      inventoryApi.endpoints.editInventory.matchFulfilled,
    ),
    (state, { payload, meta }) => {
      const inventoryProduct = state.list.entities[payload.inventory_product.id];
      if (inventoryProduct) {
        Object.assign(inventoryProduct, {
          inventory_shelf: payload.inventory_product.inventory_shelf,
          inventory_storage: payload.inventory_product.inventory_storage,
          balance: payload.inventory_product.balance,
          par: payload.inventory_product.par,
          trigger: payload.inventory_product.trigger,
          place: payload.inventory_product.place,
        });
      }
    },
  );
  builder.addMatcher(
    isAnyOf(
      stockTakeApi.endpoints.setCloseBalance.matchFulfilled,
    ),
    (state, { payload, meta }) => {
      const inventoryProduct = state.list.entities[payload.inventory_product.id];
      if (inventoryProduct) {
        Object.assign(inventoryProduct, {
          close_balance: payload.inventory_product.close_balance,
          balance: payload.inventory_product.balance,
          stock_variance: payload.inventory_product.stock_variance,
        });
      }
    },
  );
  builder.addMatcher(
    isAnyOf(
      stockTakeApi.endpoints.addSubMeasurement.matchFulfilled,
    ),
    (state, { payload, meta }) => {
      const inventoryProduct = state.list.entities[payload.sub_measurement.inventory_product_id];
      inventoryProduct?.sub_measurements.push(payload.sub_measurement);
    },
  );
  builder.addMatcher(
    isAnyOf(
      stockTakeApi.endpoints.editSubMeasurement.matchFulfilled,
    ),
    (state, { payload, meta }) => {
      const inventoryProduct = state.list.entities[payload.sub_measurement.inventory_product_id];
      const subMeasurement = inventoryProduct?.sub_measurements.find(s => s.id === payload.sub_measurement.id);
      if (subMeasurement) {
        Object.assign(subMeasurement, payload.sub_measurement);
      }
    },
  );
  builder.addMatcher(
    isAnyOf(
      stockTakeApi.endpoints.deleteSubMeasurement.matchFulfilled,
    ),
    (state, { payload, meta }) => {
      const inventoryProduct = state.list.entities[payload.sub_measurement.inventory_product_id];
      const subMeasurementIndex = inventoryProduct?.sub_measurements.findIndex(s => s.id === payload.sub_measurement.id);
      if (subMeasurementIndex !== undefined && subMeasurementIndex >= 0) {
        inventoryProduct?.sub_measurements.splice(subMeasurementIndex, 1);
      }
    },
  );
  builder.addMatcher(inventoryApi.endpoints.getInventories.matchFulfilled, (state, action) => {
    if (
      state.selectedSupplier === action.meta.arg.originalArgs.supplierId &&
      !!state.filterBy?.favourite === !!action.meta.arg.originalArgs.favourite &&
      state.filterBy?.status === action.meta.arg.originalArgs.status
    ) {
      state.list = inventoryAdapter.setMany(state.list, action.payload.inventory_products);
    }
  });
  builder.addMatcher(
    isAnyOf(inventoryApi.endpoints.favoriteInventory.matchFulfilled, inventoryApi.endpoints.unFavoriteInventory.matchFulfilled),
    (state, { payload }) => {
      state.list = state.filterBy?.favourite
        ? inventoryAdapter.removeOne(state.list, payload.product.id)
        : inventoryAdapter.upsertOne(state.list, payload.product);
    },
  );
  builder.addMatcher(inventoryApi.endpoints.deleteInventory.matchFulfilled, (state, action) => {
    state.list = inventoryAdapter.removeOne(state.list, action.meta.arg.originalArgs);
  });
  builder.addMatcher(inventoryApi.endpoints.saveInventoryProducts.matchFulfilled, (state) => {
    state.list = inventoryAdapter.getInitialState();
    state.currentPage = 1;
  });
  builder.addMatcher(inventoryApi.endpoints.skipForeverInventory.matchFulfilled, (state, action) => {
    state.list = inventoryAdapter.removeOne(state.list, action.meta.arg.originalArgs);
  });
});
