import { createStyles, makeStyles } from '@material-ui/core';
import { colorVariables } from '../../../../styles/colorVariables';

export const useStyles = makeStyles((theme) =>
  createStyles({
    head: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    saveBtn: {
      transform: 'translateX(15px)',
    },
    headBackAction: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      color: colorVariables.userNavy,
    },
    supplierHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 104,
      padding: '0 10px',
      width: '100%',
    },
    supplierInfo: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      paddingRight: 5,
    },
    avatarWrap: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      overflow: 'hidden',
      flexShrink: 0,
      marginRight: 15,
      boxShadow: `0 0 0 1px ${colorVariables.steelGrey}`,
    },
    supplierDetailsBox: {
      flex: 1,
      overflow: 'hidden',
    },
    supplierName: {
      display: 'flex',
      flexGrow: 1,
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 700,
      marginBottom: 3,
      '& span': {
        marginRight: 10,
      }
    },
    moreBtn: {
      marginLeft: 10,
      border: `2px solid ${colorVariables.grey40}`,
    },
    headActions: {
      display: 'flex',
      alignItems: 'center',
      width: 80,
    },
    dialog: {
      position: 'fixed',
      top: 100,
      right: 10,
    },
    searchWrap: {
      padding: 20,
      width: '100%',
    },
    fixedHeader: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      backgroundColor: colorVariables.white,
    },
    listHead: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colorVariables.steelGrey15,
      color: colorVariables.grey60,
      height: 32,
      padding: '0 20px',
    },
    sortTrigger: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        color: colorVariables.grey60,
        fontSize: 16,
      },
    },
    colHeadText: {
      fontSize: 10,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: colorVariables.grey60,
    },
    productsCount: {
      fontSize: 12,
      color: colorVariables.grey60,
    },
    productsList: {
      height: 'calc(100vh - 305px)',
      overflowY: 'scroll',
      paddingBottom: 60,
      position: 'fixed',
      top: 300,
      left: 0,
      right: 0,
    },
    productListAddMode: {
      top: 620,
      height: 'calc(100vh - 620px)',
    },
    addNewSaleM: {
      padding: 15,
      position: 'fixed',
      backgroundColor: colorVariables.white,
      top: 248,
      left: 0,
      right: 0,
      zIndex: 2,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
    },
    addSaleBtnM: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      '& span': {
        fontSize: 12,
        textTransform: 'uppercase',
        fontWeight: 700,
        marginLeft: 7,
      },
      '& svg': {
        color: colorVariables.green,
      },
    },
    addNewBox: {
      padding: '10px 0 0 0 ',
    },
    productImgBox: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 38,
      height: 38,
      borderRadius: '50%',
      marginRight: 10,
      backgroundColor: 'rgba(191, 201, 213, 0.6)',
      flexShrink: 0,
      cursor: 'pointer',
      overflow: 'hidden',
      '&:hover div': {
        visibility: 'visible',
      },
    },
    productImage: {
      backgroundColor: colorVariables.white,
    },
    editImage: {
      visibility: 'hidden',
      position: 'absolute',
      width: 38,
      height: 38,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(191, 201, 213, 0.6)',
      zIndex: 1,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    editIcon: {
      color: colorVariables.white,
      fontSize: 14,
    },
    editSupplierIcon: {
      color: colorVariables.green,
      fontSize: 18,
      cursor: 'pointer',
    },
    btnBox: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
      justifyContent: 'center',
    },
    expandedContent: {
      backgroundColor: 'rgba(152, 165, 183, 0.075)',
      borderRadius: 20,
      margin: '10px 10px 0 10px',
      padding: 20,
    },
    detailsRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      '&>div:last-child': {
        textAlign: 'right',
        width: '50%',
      },
    },
    detailLabel: {
      fontSize: 12,
      lineHeight: '22px',
      fontWeight: 700,
      color: colorVariables.grey60,
      width: '50%',
    },
    priceBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '50%',
      '&>div:first-child': {
        marginRight: 7,
      },
    },
    priceCell: {
      fontWeight: 600,
    },
    gstBox: {
      '& span.MuiFormControlLabel-label': {
        fontSize: '12px !important',
        lineHeight: '22px',
        fontWeight: 'bold !important',
        color: colorVariables.grey60,
      },
    },
    input: {
      width: '100%',
      border: `1px solid ${colorVariables.steelGrey}`,
      padding: '5px 10px',
      borderRadius: 4,
      textAlign: 'right',
      '&:hover, &:focus': {
        border: `1px solid ${colorVariables.blue}`,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::placeholder': {
        color: colorVariables.grey60,
      },
    },
    priceInput: {
      textAlign: 'left',
      fontWeight: 600,
      '&::placeholder': {
        fontWeight: 600,
      },
    },
    nameField: {
      textAlign: 'left',
    },
    nameWrap: {
      display: 'flex',
      padding: '0 10px',
      alignItems: 'center',
      '&>div:last-child': {
        flexGrow: 1,
      },
    },
    loadingBox: {
      paddingTop: 150,
    },
  }),
);
