import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Collapse } from '@material-ui/core';
import { ChevronLeft, KeyboardArrowDown } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import clsx from 'clsx';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useInView } from 'react-intersection-observer';
import LinesEllipsis from 'react-lines-ellipsis';
import { useDebouncedCallback } from 'use-debounce';
import { rootApi } from '../../../../api';
import {
  useAddManualProductMutation,
  useArchiveAllManualSupplerProductsMutation,
  useDeleteAllManualProductsMutation,
  useGetManualProductsQuery,
  useUpdateManualProductsMutation,
} from '../../../../api/product';
import { ProductsSortBy } from '../../../../api/product/types';
import { useGetMySupplierById } from '../../../../api/supplier/hooks';
import { ManualSupplierAction } from '../../../../api/supplier/types';
import { SupplierUser } from '../../../../api/user/types';
import SvgEditIcon from '../../../../assets/icons/EditIcon';
import { AddButton } from '../../../../shared/components/add-button';
import { BlurredImage } from '../../../../shared/components/blurred-image';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { Dialog } from '../../../../shared/components/dialog';
import { DropdownMenu } from '../../../../shared/components/dropdown-menu';
import { FormikCheckbox } from '../../../../shared/components/formik-checkbox';
import { MoreButton } from '../../../../shared/components/more-button';
import { SearchField } from '../../../../shared/components/search-field';
import { roundNumbers } from '../../../../shared/helpers/roundNumbers';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { ToastService } from '../../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  getManualProductSortFilters,
  getManualProductUpdateData,
  manualProductsSelectors,
  setManualProductsFilterBy,
  setManualProductsKeyword,
  setManualProductsPage,
  setManualProductsSortBy,
} from '../../../../store/manual-products';
import { setManualSupplierAction } from '../../../../store/suppliers-categories';
import { MainNavBar } from '../../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';
import { UploadProductLogo } from '../../../AppDrawer/SupplierDrawer/Product/common/upload-product-logo';
import { AddProductValues } from '../common/manual-supplier-products';
import { UploadPricingModal } from '../common/upload-pricing';
import { ManualProductItemMobile } from './manual-product-item-mobile';
import { OpLoading } from '../../../../shared/components/op-loading';

import { useStyles } from './style';

export const ManualProductsMobile: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { goBack, push } = useHistory();
  const [inViewRef, inView] = useInView();
  const inputWrapRef = useRef<HTMLDivElement | null>(null);

  const [addProductActive, setAddProductActive] = useState(false);
  const [isArchiveWarningShown, setIsArchiveWarningShown] = useState(false);
  const [isDeleteWarningShown, setIsDeleteWarningShown] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [editedRows, setEditedRows] = useState<number[]>([]);
  const [imageFile, setImageFile] = useState<Blob | null>(null);
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);

  const { filter: productFilter, sort, keyword, currentPage } = useAppSelector(getManualProductSortFilters);
  const supplier = useGetMySupplierById(parseFloat(params.id), true) as SupplierUser | undefined;
  const [deleteAllProducts] = useDeleteAllManualProductsMutation();
  const [archiveAllProducts] = useArchiveAllManualSupplerProductsMutation();
  const [updateProducts, { isLoading: updateLoading }] = useUpdateManualProductsMutation();
  const { data, isFetching } = useGetManualProductsQuery(
    {
      filter: productFilter,
      sort,
      keyword,
      currentPage,
    },
    { skip: !productFilter?.supplier_id },
  );
  const products = useAppSelector(manualProductsSelectors.selectAll);
  const updateData = useAppSelector(getManualProductUpdateData);
  const [addProduct, { isLoading: addProductLoading }] = useAddManualProductMutation();

  const onConfirmDelete = async () => {
    setIsDeleteWarningShown(false);
    await deleteAllProducts(parseFloat(params.id));
    ToastService.success('All your products have been deleted');
    goBack();
  };

  const onConfirmArchive = () => {
    setIsArchiveWarningShown(false);
    archiveAllProducts(parseFloat(params.id));
  };

  const onApplySort = (sortBy: ProductsSortBy) => {
    dispatch(setManualProductsSortBy(sortBy));
  };

  const onAddNewProduct = () => {
    setAddProductActive(true);
    const timer = setTimeout(() => {
      if (inputWrapRef.current) {
        const inputElement = inputWrapRef.current.querySelector('input');
        inputElement?.focus();
        clearTimeout(timer);
      }
    }, 510);
  };

  const showLockedWarning = () => {
    ToastService.warning('Action is not allowed');
  };

  const onSearchProducts = (searchValue?: string) => {
    dispatch(setManualProductsKeyword({ keyword: searchValue }));
  };

  const onUploadProducts = () => {
    if (supplier) {
      dispatch(setManualSupplierAction({ id: supplier.id, action: ManualSupplierAction.UPLOAD }));
    }
  };

  const onEditSupplierClick = () => {
    supplier && !supplier.locked_supplier && push(`/account/edit-manual-supplier/${supplier.id}`);
  };

  const saveProducts = () => {
    setBtnDisabled(true);
    onUpdateProducts();
  };

  const closeImageUpload = () => {
    setIsImageUploadModalOpen(false);
  };

  const onImageUploaded = (blob: Blob) => {
    setImageFile(blob);
  };

  const onAddProduct = async (
    { product_name, product_id, gst, price, unit, brand, sub_category, barcode }: AddProductValues,
    formikHelpers: FormikHelpers<any>,
  ) => {
    if (!supplier?.id) {
      return;
    }

    const formData = new FormData();
    formData.append('supplier_id', supplier.id.toString());
    formData.append('product[name]', product_name);
    product_id && formData.append('product[product_id]', product_id);
    formData.append('product[gst]', gst.toString());
    brand && formData.append('product[brand]', brand);
    price && formData.append('product[price_per_unit]', roundNumbers(price).toString());
    formData.append('product[unit]', unit);
    sub_category && formData.append('product[sub_category]', sub_category);
    barcode && formData.append('product[barcode]', barcode);
    imageFile && formData.append('product[picture]', imageFile);
    await addProduct(formData);
    formikHelpers.setSubmitting(false);
    formikHelpers.resetForm({});
    setImageFile(null);
    setAddProductActive(false);
    dispatch(rootApi.util?.invalidateTags(['Manual_Product']));
  };

  const onUpdateProducts = useDebouncedCallback(() => {
    if (supplier?.id && updateData.length) {
      const formData = new FormData();
      formData.append('supplier_id', supplier.id.toString());
      updateData.forEach((el, idx) => {
        formData.append(`supplier[products_attributes][${idx}][id]`, el.id.toString());
        formData.append(`supplier[products_attributes][${idx}][name]`, el.name);
        formData.append(`supplier[products_attributes][${idx}][unit]`, el.unit);
        formData.append(`supplier[products_attributes][${idx}][product_id]`, el.product_id || '');
        formData.append(`supplier[products_attributes][${idx}][gst]`, el.gst?.toString() || 'false');
        formData.append(`supplier[products_attributes][${idx}][brand]`, el.brand || '');
        formData.append(`supplier[products_attributes][${idx}][barcode]`, el.barcode || '');
        el.picture && typeof el.picture !== 'string' && formData.append(`supplier[products_attributes][${idx}][picture]`, el.picture);
        formData.append(`supplier[products_attributes][${idx}][price_per_unit]`, el.product_price?.toString() || '0');
      });
      updateProducts(formData);
    }
    setBtnDisabled(false);
  }, 1001);

  const openImageUpload = useCallback(() => {
    setIsImageUploadModalOpen(true);
  }, []);

  useEffect(() => {
    params.id && dispatch(setManualProductsFilterBy({ supplier_id: parseFloat(params.id) }));
  }, [params.id]);

  useEffect(() => {
    inView && data?.data.next_page && dispatch(setManualProductsPage(data.data.next_page));
  }, [inView, data]);

  useEffect(() => {
    setBtnDisabled(!editedRows.length);
  }, [editedRows]);

  return (
    <>
      <TopNavBar
        isAccount={true}
        bottomComponent={
          isMobile ? (
            <div className={classes.head}>
              <div className={classes.headBackAction} onClick={goBack}>
                <ChevronLeft />
                <span>Back to Suppliers</span>
              </div>
              <ThemedButton
                onClick={saveProducts}
                title='Save changes'
                isSmall={true}
                customClass={classes.saveBtn}
                disabled={btnDisabled || updateLoading}
              />
            </div>
          ) : undefined
        }
      />
      <MainNavBar isAccount={true} />
      <div className={classes.fixedHeader}>
        <div className={classes.supplierHeader}>
          <div className={classes.supplierInfo}>
            <div className={classes.avatarWrap}>
              <BlurredImage src={supplier?.company.picture_url || ''} />
            </div>
            <div className={classes.supplierDetailsBox}>
              <div className={classes.supplierName}>
                <span>{supplier?.company?.name || ''}</span>
                <SvgEditIcon className={classes.editSupplierIcon} onClick={onEditSupplierClick} />
              </div>
            </div>
          </div>
          <div className={classes.headActions}>
            <AddButton isDark={true} onClick={onUploadProducts} />
            <DropdownMenu
              items={[
                <div key='Add single Product' onClick={onAddNewProduct}>
                  Add single product
                </div>,
                <div key='Upload Products' onClick={onUploadProducts}>
                  Upload Products
                </div>,
                <div
                  key='Delete all Products'
                  onClick={supplier?.locked_supplier ? showLockedWarning : setIsDeleteWarningShown.bind(null, true)}
                >
                  Delete all Products
                </div>,
                <div
                  key='Archive all products'
                  onClick={supplier?.locked_supplier ? showLockedWarning : setIsArchiveWarningShown.bind(null, true)}
                >
                  Archive all products
                </div>,
              ]}
              triggerBtn={
                <div>
                  <MoreButton horizontal={true} customClass={classes.moreBtn} />
                </div>
              }
            />
          </div>
        </div>
        <div className={classes.searchWrap}>
          <SearchField placeholder={'Search product'} onChange={onSearchProducts} searchValue={keyword} />
        </div>
        <div className={classes.listHead}>
          <DropdownMenu
            items={[
              <div key='asc' onClick={onApplySort.bind(null, ['name', 'asc'])}>
                Sort A - Z
              </div>,
              <div key='desc' onClick={onApplySort.bind(null, ['name', 'desc'])}>
                Sort Z - A
              </div>,
            ]}
            triggerBtn={
              <div className={classes.sortTrigger}>
                <span className={classes.colHeadText}>PRODUCT LIST</span>
                <KeyboardArrowDown />
              </div>
            }
          />
          <div className={classes.productsCount}>{data?.products_result} products</div>
        </div>
      </div>
      <div className={classes.addNewSaleM}>
        {!addProductActive && (
          <div className={classes.addSaleBtnM} onClick={onAddNewProduct}>
            <Add />
            <span>ADD SINGLE PRODUCT</span>
          </div>
        )}
        <Collapse in={addProductActive} timeout={500}>
          <Formik
            initialValues={{
              product_name: '',
              unit: '',
              gst: false,
              price: '',
              brand: '',
              product_id: '',
              sub_category: '',
              barcode: '',
            }}
            onSubmit={onAddProduct}
            enableReinitialize={true}
          >
            {({ submitForm, values, setFieldValue }) => (
              <Form>
                <div className={classes.nameWrap}>
                  <div className={clsx([classes.productImgBox])} onClick={openImageUpload}>
                    {imageFile ? (
                      <>
                        <BlurredImage src={URL.createObjectURL(imageFile)} alt={``} />
                        <div className={clsx(classes.editImage)}>
                          <SvgEditIcon className={classes.editIcon} />
                        </div>
                      </>
                    ) : (
                      <SvgEditIcon className={classes.editIcon} />
                    )}
                  </div>
                  <div ref={inputWrapRef}>
                    <FastField name='product_name' className={clsx(classes.input, classes.nameField)} placeholder='Enter product name' />
                  </div>
                </div>
                <div className={classes.expandedContent}>
                  <div className={classes.detailsRow}>
                    <div className={classes.detailLabel}>PRODUCT ID</div>
                    <div>
                      <FastField name='product_id' className={classes.input} placeholder='Enter' />
                    </div>
                  </div>
                  <div className={classes.detailsRow}>
                    <div className={classes.detailLabel}>BRAND</div>
                    <div>
                      <FastField name='brand' className={classes.input} placeholder='Enter' />
                    </div>
                  </div>
                  <div className={classes.detailsRow}>
                    <div className={classes.detailLabel}>UNIT OF MEASURE*</div>
                    <div>
                      <FastField name='unit' className={classes.input} placeholder='Enter' />
                    </div>
                  </div>
                  <div className={classes.detailsRow}>
                    <div className={classes.detailLabel}>BARCODE</div>
                    <div>
                      <FastField name='barcode' className={classes.input} placeholder='Enter' />
                    </div>
                  </div>
                  <div className={classes.detailsRow}>
                    <div className={classes.detailLabel}>SUB CATEGORY</div>
                    <div>
                      <FastField name='sub_category' className={classes.input} placeholder='Enter' />
                    </div>
                  </div>
                  <div className={classes.detailsRow}>
                    <div className={classes.priceBox}>
                      <div className={classes.detailLabel}>PRICE</div>
                      <div>
                        <input
                          type={'number'}
                          className={clsx(classes.input, classes.priceInput)}
                          value={values.price}
                          placeholder='$0.00'
                          onChange={(e) => {
                            setFieldValue('price', roundNumbers(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.gstBox}>
                      <FastField type='checkbox' name='gst'>
                        {(fieldProps: FieldProps) => <FormikCheckbox {...fieldProps} labelPlace='start' label={'TAX*'} />}
                      </FastField>
                    </div>
                  </div>
                  <div className={classes.btnBox}>
                    <ThemedButton
                      onClick={() => {
                        setAddProductActive(false);
                        setImageFile(null);
                      }}
                      title='Cancel'
                      buttonStyle='secondary'
                      isSmall={true}
                      width={140}
                      type='reset'
                    />
                    <ThemedButton
                      onClick={submitForm}
                      title='Save product'
                      isSmall={true}
                      width={142}
                      disabled={addProductLoading || !values.product_name.trim() || !values.unit.trim()}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Collapse>
      </div>
      <div className={clsx(classes.productsList, addProductActive && classes.productListAddMode)}>
        <OpLoading loading={isFetching} dataLoaded={!!products.length} boxClasses={classes.loadingBox}>
          {products.map((el, idx, arr) => (
            <div key={el.id} ref={idx === arr.length - 13 ? inViewRef : undefined}>
              <ManualProductItemMobile
                item={el}
                lockedSupplier={!!supplier?.locked_supplier}
                setEditedRows={setEditedRows}
                currentRowDisabled={!editedRows.includes(el.id)}
              />
            </div>
          ))}
        </OpLoading>
      </div>

      {isDeleteWarningShown && (
        <Dialog
          title='Are you sure you want to delete all products?'
          onCancel={setIsDeleteWarningShown.bind(null, false)}
          onConfirm={onConfirmDelete}
          customClass={classes.dialog}
        />
      )}
      {isArchiveWarningShown && (
        <Dialog
          title='Are you sure you want to archive all products?'
          onCancel={setIsArchiveWarningShown.bind(null, false)}
          onConfirm={onConfirmArchive}
          customClass={classes.dialog}
        />
      )}
      <UploadPricingModal />
      <UploadProductLogo isModalOpen={isImageUploadModalOpen} onClose={closeImageUpload} onSubmit={onImageUploaded} />
    </>
  );
};
