import React, { useCallback, useEffect, useRef, useState } from 'react';

import { KeyboardArrowDown } from '@material-ui/icons';
import clsx from 'clsx';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import { useInView } from 'react-intersection-observer';
import LinesEllipsis from 'react-lines-ellipsis';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { rootApi } from '../../../../../api';
import {
  useAddManualProductMutation,
  useArchiveAllManualSupplerProductsMutation,
  useDeleteAllManualProductsMutation,
  useGetManualProductsQuery,
  useRestoreAllArchivedMutation,
  useUpdateManualProductsMutation,
} from '../../../../../api/product';
import { Product, ProductsSortBy } from '../../../../../api/product/types';
import { useGetMySupplierById } from '../../../../../api/supplier/hooks';
import { ManualSupplierAction } from '../../../../../api/supplier/types';
import { SupplierUser } from '../../../../../api/user/types';
import SvgEditIcon from '../../../../../assets/icons/EditIcon';
import { BlurredImage } from '../../../../../shared/components/blurred-image';
import { ThemedButton } from '../../../../../shared/components/themed-button';
import { CommonCheckbox } from '../../../../../shared/components/common-checkbox';
import { Dialog } from '../../../../../shared/components/dialog';
import { DropdownMenu } from '../../../../../shared/components/dropdown-menu';
import { MoreButton } from '../../../../../shared/components/more-button';
import { OpLoading } from '../../../../../shared/components/op-loading';
import { OverlayModal } from '../../../../../shared/components/overlay-modal';
import { SearchField } from '../../../../../shared/components/search-field';
import { roundNumbers } from '../../../../../shared/helpers/roundNumbers';
import { ToastService } from '../../../../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  clearManualProductsList,
  clearManualProductsUpdateData,
  getManualProductSortFilters,
  getManualProductUpdateData,
  manualProductsSelectors,
  setManualProductsFilterBy,
  setManualProductsKeyword,
  setManualProductsPage,
  setManualProductsSortBy,
} from '../../../../../store/manual-products';
import { getManualSupplierAction, setManualSupplierAction } from '../../../../../store/suppliers-categories';
import { UploadProductLogo } from '../../../../AppDrawer/SupplierDrawer/Product/common/upload-product-logo';
import { ManualSupplierProductItem } from '../manual-supplier-product-item';

import { useStyles } from './style';

export interface AddProductValues {
  product_name: string;
  unit: string;
  brand?: string;
  price?: string;
  gst: boolean;
  product_id?: string;
  sub_category?: string;
  barcode?: string;
}

export const ManualSupplierProducts: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const [inViewRef, inView] = useInView();
  const inputWrapRef = useRef<HTMLDivElement | null>(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [imageFile, setImageFile] = useState<Blob | null>(null);
  const [editRowFinished, setEditRowFinished] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [editedRows, setEditedRows] = useState<number[]>([]);
  const [isArchiveWarningShown, setIsArchiveWarningShown] = useState(false);
  const [isDeleteWarningShown, setIsDeleteWarningShown] = useState(false);
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);
  const manualSupplierAction = useAppSelector(getManualSupplierAction);
  const isOpen = manualSupplierAction?.action === ManualSupplierAction.VIEW || manualSupplierAction?.action === ManualSupplierAction.EDIT;

  const supplier = useGetMySupplierById(manualSupplierAction?.id || -1, true) as SupplierUser | undefined;

  const { filter: productFilter, sort, keyword, currentPage } = useAppSelector(getManualProductSortFilters);
  const products = useAppSelector(manualProductsSelectors.selectAll);
  const updateData = useAppSelector(getManualProductUpdateData);

  const [deleteAllProducts] = useDeleteAllManualProductsMutation();
  const [archiveAllProducts] = useArchiveAllManualSupplerProductsMutation();
  const [restoreArchiveAllProducts] = useRestoreAllArchivedMutation();
  const [updateProducts, { isLoading: updateLoading }] = useUpdateManualProductsMutation();
  const [addProduct, { isLoading: addProductLoading }] = useAddManualProductMutation();

  const { data, isFetching } = useGetManualProductsQuery(
    {
      filter: productFilter,
      sort,
      keyword,
      currentPage,
    },
    { skip: !productFilter?.supplier_id },
  );

  const closeModal = () => {
    dispatch(setManualSupplierAction(null));
    dispatch(clearManualProductsList());
    dispatch(clearManualProductsUpdateData());
  };

  const saveProducts = () => {
    setBtnDisabled(true);
    onUpdateProducts();
  };

  const showLockedWarning = () => {
    ToastService.warning('Action is not allowed');
  };

  const onUpdateProducts = useDebouncedCallback(() => {
    if (supplier?.id && updateData.length) {
      const formData = new FormData();
      formData.append('supplier_id', supplier.id.toString());
      updateData.forEach((el, idx) => {
        formData.append(`supplier[products_attributes][${idx}][id]`, el.id.toString());
        formData.append(`supplier[products_attributes][${idx}][name]`, el.name);
        formData.append(`supplier[products_attributes][${idx}][unit]`, el.unit);
        el.product_id && formData.append(`supplier[products_attributes][${idx}][product_id]`, el.product_id);
        formData.append(`supplier[products_attributes][${idx}][gst]`, el.gst?.toString() || 'false');
        el.brand && formData.append(`supplier[products_attributes][${idx}][brand]`, el.brand);
        el.sub_category && formData.append(`supplier[products_attributes][${idx}][sub_category]`, el.sub_category);
        el.barcode && formData.append(`supplier[products_attributes][${idx}][barcode]`, el.barcode);
        el.picture && typeof el.picture !== 'string' && formData.append(`supplier[products_attributes][${idx}][picture]`, el.picture);
        formData.append(`supplier[products_attributes][${idx}][price_per_unit]`, el.product_price?.toString() || '0');
      });
      updateProducts(formData);
      setIsEditMode(false);
      setEditRowFinished(true);
    }
    setBtnDisabled(false);
    closeModal();
  }, 1001);

  const onConfirmDelete = async () => {
    if (supplier) {
      setIsDeleteWarningShown(false);
      await deleteAllProducts(supplier.id);
      closeModal();
    }
  };

  const onAddProduct = async (
    { product_name, product_id, gst, price, unit, brand, sub_category, barcode }: AddProductValues,
    formikHelpers: FormikHelpers<any>,
  ) => {
    if (!supplier?.id) {
      return;
    }

    const formData = new FormData();
    formData.append('supplier_id', supplier.id.toString());
    formData.append('product[name]', product_name);
    product_id && formData.append('product[product_id]', product_id);
    formData.append('product[gst]', gst.toString());
    brand && formData.append('product[brand]', brand);
    price && formData.append('product[price_per_unit]', roundNumbers(price).toString());
    formData.append('product[unit]', unit);
    sub_category && formData.append('product[sub_category]', sub_category);
    barcode && formData.append('product[barcode]', barcode);
    imageFile && formData.append('product[picture]', imageFile);
    await addProduct(formData);
    formikHelpers.setSubmitting(false);
    formikHelpers.resetForm({});
    setImageFile(null);
    dispatch(rootApi.util?.invalidateTags(['Manual_Product']));
  };

  const onConfirmArchive = () => {
    setIsArchiveWarningShown(false);
    archiveAllProducts(manualSupplierAction?.id as number);
  };

  const openImageUpload = useCallback(() => {
    setIsImageUploadModalOpen(true);
  }, []);

  const onApplySort = (sortBy: ProductsSortBy) => {
    dispatch(setManualProductsSortBy(sortBy));
  };

  const onSearchProducts = (searchValue?: string) => {
    dispatch(setManualProductsKeyword({ keyword: searchValue }));
  };

  const onSetUploadMode = () => {
    closeModal();
    dispatch(setManualSupplierAction({ id: supplier?.id as number, action: ManualSupplierAction.UPLOAD }));
  };

  const onEditSupplierClick = () => {
    supplier && !supplier.locked_supplier && push(`/account/edit-manual-supplier/${supplier.id}`);
  };

  const onImageUploaded = (blob: Blob) => {
    setImageFile(blob);
  };

  const closeImageUpload = () => {
    setIsImageUploadModalOpen(false);
  };

  const onAddNewProduct = useDebouncedCallback(() => {
    if (inputWrapRef.current) {
      const inputElement = inputWrapRef.current.querySelector('input');
      inputElement?.focus();
    }
  }, 100);

  useEffect(() => {
    manualSupplierAction?.id && dispatch(setManualProductsFilterBy({ supplier_id: manualSupplierAction?.id }));
    if (manualSupplierAction?.action === ManualSupplierAction.EDIT) {
      setIsEditMode(true);
      setEditRowFinished(false);
    }
    if (manualSupplierAction?.action === ManualSupplierAction.VIEW) {
      setIsEditMode(false);
      setEditRowFinished(true);
    }
  }, [manualSupplierAction]);

  useEffect(() => {
    inView && data?.data.next_page && dispatch(setManualProductsPage(data.data.next_page));
  }, [inView, data]);

  useEffect(() => {
    setBtnDisabled(!editedRows.length);
  }, [editedRows]);

  useEffect(() => {
    if (!isOpen) {
      setEditedRows([]);
      setBtnDisabled(true);
    }
  }, [isOpen]);

  return (
    <OverlayModal isOpen={isOpen} onClose={closeModal} animate={true} boxClassName={classes.modalRoot}>
      <div>
        <div className={classes.header}>
          <div className={classes.supplierInfo}>
            <div className={classes.avatarWrap}>
              <BlurredImage src={supplier?.company.picture_url || ''} />
            </div>
            <div className={classes.supplierDetailsBox}>
              <div className={classes.supplierName}>
                <span>{supplier?.company?.name || ''}</span>
                <SvgEditIcon className={classes.editSupplierIcon} onClick={onEditSupplierClick} />
              </div>
              <div className={classes.supplierAddress}>
                {supplier?.company_addresses && supplier?.company_addresses[0]?.city && supplier?.company_addresses[0]?.state
                  ? `${supplier.company_addresses[0].city.slice(0, 20)}, ${supplier.company_addresses[0].state.toUpperCase()}`
                  : ''}
              </div>
            </div>
          </div>
          <ThemedButton onClick={saveProducts} title='Save changes' width={192} disabled={updateLoading || btnDisabled} />
        </div>
        <div className={classes.headActions}>
          <div className={classes.actionsTitle}>Products and prices</div>
          <ThemedButton
            buttonStyle='primaryBordered'
            onClick={onSetUploadMode}
            title='Upload Products & Prices'
            bordered={true}
            isSmall={true}
          />
          <DropdownMenu
            items={[
              <div key='Add Products' onClick={onAddNewProduct}>
                Add single Product
              </div>,
              <div
                key='Edit Products'
                onClick={() => {
                  setEditRowFinished(false);
                  setIsEditMode(true);
                }}
              >
                Edit Products
              </div>,
              <div
                key='Delete all Products'
                onClick={supplier?.locked_supplier ? showLockedWarning : setIsDeleteWarningShown.bind(null, true)}
              >
                Delete all Products
              </div>,
              <div
                key='Archive all products'
                onClick={supplier?.locked_supplier ? showLockedWarning : setIsArchiveWarningShown.bind(null, true)}
              >
                Archive all Products
              </div>,
              <div
                key='Restore all products'
                onClick={
                  supplier?.locked_supplier
                    ? showLockedWarning
                    : () => {
                        supplier && restoreArchiveAllProducts(supplier.id);
                      }
                }
              >
                Restore all archived
              </div>,
            ]}
            triggerBtn={
              <div>
                <MoreButton horizontal={true} customClass={classes.moreBtn} />
              </div>
            }
          />
        </div>
        <div className={classes.searchBox}>
          <div className={classes.searchWrap}>
            <SearchField placeholder={'Search products...'} onChange={onSearchProducts} searchValue={keyword} />
          </div>
          <div className={classes.productsCount}>{data?.products_result} uploaded products</div>
        </div>
        <div>
          <div className={classes.tableHead}>
            <div className={classes.name}>
              <DropdownMenu
                items={[
                  <div key='asc' onClick={onApplySort.bind(null, ['name', 'asc'])}>
                    Sort A - Z
                  </div>,
                  <div key='desc' onClick={onApplySort.bind(null, ['name', 'desc'])}>
                    Sort Z - A
                  </div>,
                ]}
                triggerBtn={
                  <div className={classes.sortTrigger}>
                    <span className={classes.colHeadText}>DESCRIPTION*</span>
                    <KeyboardArrowDown />
                  </div>
                }
              />
            </div>
            <div className={classes.brand}>
              <DropdownMenu
                items={[
                  <div key='asc' onClick={onApplySort.bind(null, ['brand', 'asc'])}>
                    Sort A - Z
                  </div>,
                  <div key='desc' onClick={onApplySort.bind(null, ['brand', 'desc'])}>
                    Sort Z - A
                  </div>,
                ]}
                triggerBtn={
                  <div className={classes.sortTrigger}>
                    <span className={classes.colHeadText}>Brand</span>
                    <KeyboardArrowDown />
                  </div>
                }
              />
            </div>
            <div className={classes.id}>
              <DropdownMenu
                items={[
                  <div key='asc' onClick={onApplySort.bind(null, ['product_id', 'asc'])}>
                    Sort A - Z
                  </div>,
                  <div key='desc' onClick={onApplySort.bind(null, ['product_id', 'desc'])}>
                    Sort Z - A
                  </div>,
                ]}
                triggerBtn={
                  <div className={classes.sortTrigger}>
                    <span className={classes.colHeadText}>PRODUCT ID</span>
                    <KeyboardArrowDown />
                  </div>
                }
              />
            </div>
            <div className={classes.unit}>
              <DropdownMenu
                items={[
                  <div key='asc' onClick={onApplySort.bind(null, ['unit', 'asc'])}>
                    Sort A - Z
                  </div>,
                  <div key='desc' onClick={onApplySort.bind(null, ['unit', 'desc'])}>
                    Sort Z - A
                  </div>,
                ]}
                triggerBtn={
                  <div className={classes.sortTrigger}>
                    <span className={classes.colHeadText}>Unit of Measure* </span>
                    <KeyboardArrowDown />
                  </div>
                }
              />
            </div>
            <div className={classes.barcode}>
              <DropdownMenu
                items={[
                  <div key='asc' onClick={onApplySort.bind(null, ['barcode', 'asc'])}>
                    Sort A - Z
                  </div>,
                  <div key='desc' onClick={onApplySort.bind(null, ['barcode', 'desc'])}>
                    Sort Z - A
                  </div>,
                ]}
                triggerBtn={
                  <div className={classes.sortTrigger}>
                    <span className={classes.colHeadText}>Barcode </span>
                    <KeyboardArrowDown />
                  </div>
                }
              />
            </div>
            <div className={classes.subCat}>
              <DropdownMenu
                items={[
                  <div key='asc' onClick={onApplySort.bind(null, ['sub_category', 'asc'])}>
                    Sort A - Z
                  </div>,
                  <div key='desc' onClick={onApplySort.bind(null, ['sub_category', 'desc'])}>
                    Sort Z - A
                  </div>,
                ]}
                triggerBtn={
                  <div className={classes.sortTrigger}>
                    <span className={classes.colHeadText}>SUb category </span>
                    <KeyboardArrowDown />
                  </div>
                }
              />
            </div>
            <div className={classes.price}>
              <DropdownMenu
                items={[
                  <div key='asc' onClick={onApplySort.bind(null, ['price_per_unit', 'asc'])}>
                    Sort Low to High
                  </div>,
                  <div key='desc' onClick={onApplySort.bind(null, ['price_per_unit', 'desc'])}>
                    Sort High to Low
                  </div>,
                ]}
                triggerBtn={
                  <div className={classes.sortTrigger}>
                    <span className={classes.colHeadText}>PRICE</span>
                    <KeyboardArrowDown />
                  </div>
                }
              />
            </div>
            <div className={classes.gst}>
              <span className={classes.colHeadText}>TAX*</span>
            </div>
            <div className={classes.actions} />
          </div>
          <Formik
            initialValues={{
              product_name: '',
              unit: '',
              gst: false,
              price: '',
              brand: '',
              product_id: '',
              sub_category: '',
              barcode: '',
            }}
            onSubmit={onAddProduct}
            enableReinitialize={true}
          >
            {({ submitForm, values, setFieldValue }) => (
              <Form className={classes.addRow}>
                <div className={clsx(classes.name, classes.nameCell)}>
                  <div>
                    <div className={clsx([classes.productImgBox, imageFile && classes.productImage])} onClick={openImageUpload}>
                      {imageFile ? (
                        <>
                          <BlurredImage src={URL.createObjectURL(imageFile)} alt={``} />
                          <div className={clsx(classes.editImage)}>
                            <SvgEditIcon className={classes.editIcon} />
                          </div>
                        </>
                      ) : (
                        <SvgEditIcon className={classes.editIcon} />
                      )}
                    </div>
                  </div>
                  <div ref={inputWrapRef}>
                    <FastField name='product_name' className={clsx(classes.input)} placeholder={'Enter product name'} />
                  </div>
                </div>
                <div className={classes.brand}>
                  <FastField name='brand' className={classes.input} placeholder='Enter' />
                </div>
                <div className={classes.id}>
                  <FastField name='product_id' className={classes.input} placeholder='Enter' />
                </div>
                <div className={classes.unit}>
                  <FastField name='unit' className={classes.input} placeholder='Enter' />
                </div>
                <div className={classes.barcode}>
                  <FastField name='barcode' className={classes.input} placeholder='Enter' />
                </div>
                <div className={classes.subCat}>
                  <FastField name='sub_category' className={classes.input} placeholder='Enter' />
                </div>
                <div className={classes.price}>
                  <FastField name='price'>
                    {() => (
                      <input
                        type='number'
                        onChange={(e) => setFieldValue('price', roundNumbers(e.target.value).toString() || '')}
                        className={clsx(classes.input, classes.priceCell)}
                        value={values.price?.toString() || ''}
                        placeholder='$0.00'
                      />
                    )}
                  </FastField>
                </div>
                <div className={clsx(classes.gst, classes.checkBoxWrap)}>
                  <FastField type='checkbox' name='gst'>
                    {() => <CommonCheckbox checked={values.gst} name={''} onChange={() => setFieldValue('gst', !values.gst)} />}
                  </FastField>
                </div>
                <div className={classes.actions}>
                  <ThemedButton
                    onClick={submitForm}
                    title='Add'
                    isSmall={true}
                    width={50}
                    customClass={classes.submitBtn}
                    disabled={!values.product_name.trim() || !values.unit.trim() || addProductLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <div className={classes.tableContent}>
            <OpLoading loading={isFetching} dataLoaded={!!products.length} boxClasses={classes.loadingBox}>
              {products.map((item: Product, idx, arr) => (
                <div key={item.id} ref={idx === arr.length - 13 ? inViewRef : undefined}>
                  <ManualSupplierProductItem
                    item={item}
                    editMode={isEditMode}
                    onEditRow={setEditRowFinished.bind(null, false)}
                    rowEditFinished={editRowFinished}
                    lockedSupplier={!!supplier?.locked_supplier}
                    setEditedRows={setEditedRows}
                  />
                </div>
              ))}
            </OpLoading>
          </div>
        </div>
        {isDeleteWarningShown && (
          <Dialog
            title='Are you sure you want to delete all products?'
            onCancel={setIsDeleteWarningShown.bind(null, false)}
            onConfirm={onConfirmDelete}
            customClass={classes.dialog}
          />
        )}
        {isArchiveWarningShown && (
          <Dialog
            title='Are you sure you want to archive all products?'
            onCancel={setIsArchiveWarningShown.bind(null, false)}
            onConfirm={onConfirmArchive}
            customClass={classes.dialog}
          />
        )}
        <UploadProductLogo isModalOpen={isImageUploadModalOpen} onClose={closeImageUpload} onSubmit={onImageUploaded} />
      </div>
    </OverlayModal>
  );
};
