import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';

import { Menu } from '@material-ui/core';
import { differenceInDays, format, isAfter, isBefore, isValid, parse } from 'date-fns';
import { DateRange } from 'react-date-range';
import MaskedInput from 'react-text-mask';
import { colorVariables } from '../../../styles/colorVariables';
import { ThemedButton } from '../themed-button';
import clsx from 'clsx';
import SvgInfoIconCalendar from '../../../assets/images/svg-components/InfoIconCalendar';

import { useStyles } from './style';

export interface IDateRange {
  startDate?: Date;
  endDate?: Date;
  key?: string;
}

interface Props {
  onDateChange: (data: IDateRange) => void;
  startDate?: Date;
  endDate?: Date;
  trigger: ReactNode;
  alignBottom?: boolean;
  onPickerClose?: () => void;
  minDate?: Date;
  maxDate?: Date;
  clearOnApply?: boolean;
  maxDays?: number;
}

export const DateRangeComponent: React.FC<Props> = ({
  onDateChange,
  startDate,
  endDate,
  trigger,
  alignBottom,
  onPickerClose,
  minDate = new Date(0),
  maxDate = new Date(9999999999999),
  clearOnApply,
  maxDays,
}) => {
  const classes = useStyles();
  const [range, setRange] = useState<IDateRange>({
    startDate: startDate || new Date(),
    endDate: endDate || new Date(),
    key: 'selection',
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isWarningShown, setIsWarningShown] = useState<{ startDate: boolean; endDate: boolean }>({
    startDate: false,
    endDate: false,
  });
  const [inputDates, setInputDates] = useState<{ startDate?: Date; endDate?: Date }>({
    startDate: undefined,
    endDate: undefined,
  });

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    clearOnApply && onPickerClose && onPickerClose();
  };

  const onClose = () => {
    handleClose();
    setRange((state) => ({ ...state, startDate: new Date(), endDate: new Date() }));
    onDateChange({ startDate: undefined, endDate: undefined });
  };

  const onApply = () => {
    anchorEl ? onDateChange(range) : onDateChange({ startDate: undefined, endDate: undefined });
    setAnchorEl(null);
    clearOnApply && setRange((state) => ({ ...state, startDate: new Date(), endDate: new Date() }));
  };

  const onInputDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const shouldValidate = !value.includes('_');
    if (shouldValidate) {
      const parsedDate = parse(e.target.value, 'dd/MM/yy', new Date());
      if (isValid(parsedDate) && isAfter(parsedDate, minDate) && isBefore(parsedDate, maxDate)) {
        if (range?.endDate && isAfter(parsedDate, range.endDate)) {
          setInputDates((state) => ({ ...state, endDate: parsedDate }));
          setRange((state) => ({ ...state, endDate: parsedDate }));
        } else if (range?.startDate && isBefore(parsedDate, range.startDate)) {
          setInputDates((state) => ({ ...state, startDate: parsedDate }));
          setRange((state) => ({ ...state, startDate: parsedDate }));
        } else {
          setInputDates((state) => ({ ...state, [e.target.name]: parsedDate }));
          setRange((state) => ({ ...state, [e.target.name]: parsedDate }));
        }
        setIsWarningShown((state) => ({ ...state, [e.target.name]: false }));
      } else {
        setInputDates({ [e.target.name]: undefined });
        setIsWarningShown((state) => ({ ...state, [e.target.name]: true }));
      }
    } else {
      setInputDates({ [e.target.name]: undefined });
    }
  };

  useEffect(() => {
    setIsWarningShown({
      startDate: false,
      endDate: false,
    });
    setInputDates({
      startDate: range.startDate,
      endDate: range.endDate,
    });
  }, [range]);

  useEffect(() => {
    setRange((state) => ({ ...state, startDate: startDate || new Date(), endDate: endDate || new Date() }));
  }, [startDate, endDate]);

  return (
    <div className={classes.root}>
      <div onClick={handleClick} className={classes.triggerBox}>
        {trigger}
      </div>
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.container }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: alignBottom ? 'bottom' : 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: alignBottom ? 'bottom' : 'top', horizontal: 'right' }}
      >
        <div className={classes.inputBox}>
          <div className={classes.inputWrap}>
            <label htmlFor='from' className={classes.label}>
              From
            </label>
            {isWarningShown.startDate && <div className={classes.toolTip}>Invalid date</div>}
            <MaskedInput
              id='from'
              placeholder='dd/mm/yy'
              name='startDate'
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
              value={inputDates.startDate ? format(inputDates.startDate, 'dd/MM/yy') : undefined}
              onChange={onInputDateChange}
              className={clsx(classes.input, maxDays && classes.inputGreen)}
            />
          </div>
          <div className={classes.inputWrap}>
            <label htmlFor='to' className={classes.label}>
              To
            </label>
            {isWarningShown.endDate && <div className={classes.toolTip}>Invalid date</div>}
            <MaskedInput
              id='to'
              placeholder='dd/mm/yy'
              name='endDate'
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
              value={inputDates.endDate ? format(inputDates.endDate, 'dd/MM/yy') : undefined}
              onChange={onInputDateChange}
              className={clsx(
                classes.input,
                maxDays && classes.inputGreen,
                range?.startDate &&
                  range?.endDate &&
                  maxDays &&
                  differenceInDays(range.endDate, range.startDate) >= maxDays &&
                  classes.inputRed,
              )}
            />
          </div>
        </div>
        <DateRange
          showDateDisplay={false}
          fixedHeight={true}
          className={clsx(
            classes.calendar,
            range?.startDate &&
              range?.endDate &&
              maxDays &&
              differenceInDays(range.endDate, range.startDate) >= maxDays &&
              classes.calendarRed,
          )}
          rangeColors={[colorVariables.greenLight]}
          weekStartsOn={1}
          months={1}
          onChange={(item) => setRange(item.selection as IDateRange)}
          moveRangeOnFirstSelection={false}
          ranges={[range]}
          showMonthAndYearPickers={false}
          maxDate={maxDate}
          minDate={minDate}
        />

        <div className={classes.btnBoxWrap}>
          {maxDays && (
            <div
              className={clsx(
                classes.maxDaysWarning,
                range?.startDate &&
                  range?.endDate &&
                  maxDays &&
                  differenceInDays(range.endDate, range.startDate) >= maxDays &&
                  classes.maxDaysWarningRed,
              )}
            >
              <SvgInfoIconCalendar
                color={
                  range?.startDate && range?.endDate && maxDays && differenceInDays(range.endDate, range.startDate) >= maxDays
                    ? '#E63030'
                    : undefined
                }
              />
              <div>You can choose a period of no more than {maxDays} days</div>
            </div>
          )}
          <div className={classes.btnBox}>
            <ThemedButton title='Clear' onClick={onClose} isSmall={true} width={50} buttonStyle='secondary' />
            <ThemedButton
              title='Apply'
              onClick={onApply}
              isSmall={true}
              width={50}
              disabled={!!range?.startDate && !!range?.endDate && !!maxDays && differenceInDays(range.endDate, range.startDate) >= maxDays}
            />
          </div>
        </div>
      </Menu>
    </div>
  );
};
