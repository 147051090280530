import { RootState } from '../index';
import { inventoryAdapter } from './inventory.reducer';

export const getInventorySortFilters = (state: RootState) => ({
  currentPage: state.inventory.currentPage,
  sortBy: state.inventory.sortBy,
  filter: state.inventory.filterBy,
  keyword: state.inventory.keyword,
  supplier: state.inventory.selectedSupplier,
  storage: state.inventory.selectedStorage,
});

export const getInventoryStorageFilters = (state: RootState) => ({
  keyword: state.inventory.storageKeyword,
  supplier: state.inventory.storageSelectedSupplier,
  favorite: state.inventory.filterBy?.favourite,
  recently: state.inventory.filterBy?.recently,
});

export const getReplenishLoading = (state: RootState) => state.inventory.replenishLoading;

export const getInventoryProductId = (state: RootState) => state.inventory.addSubMeasureFor;

export const getIdForSubMeasure = (state: RootState) => state.inventory.subMeasureId;

export const getEditModeRow = (state: RootState) => state.inventory.editModeRow;

export const inventorySelectors = inventoryAdapter.getSelectors((state: RootState) => state.inventory.list);
