import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  AddSubMeasurementRes,
  ExportStockTakeRes,
  SetupCloseBalanceRes,
  StartStockTakeRes,
  StockTakeHistoryParams,
  StockTakeHistoryRes,
  StockTakesListRes,
  StockTakesListParams,
} from './types';
import { inventoryApi } from '../inventory';
import store from '../../store';
import { setAppSuccessToast } from '../../store/user';
import { generateStockTakeSheet } from '../../shared/helpers/generateStockTakeSheet';
import { resetInventoryList } from '../../store/inventory';
import { StocktakeMode } from '../inventory/types';
import { getStockTakesListParamsHelper } from '../helpers';

export const stockTakeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getStockTakes: builder.query<StockTakesListRes, StockTakesListParams>({
      query: (args) => {
        const params = {
          ...getStockTakesListParamsHelper(args),
        };
        return {
          url: `/stock_takes`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['StockTake'],
    }),

    getStockTakeHistory: builder.query<StockTakeHistoryRes, StockTakeHistoryParams>({
      query: ({ id, ...params }) => {
        return {
          url: `/stock_takes/${id}`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['StockTake'],
    }),

    startStockTake: builder.mutation<StartStockTakeRes, void>({
      query: () => {
        return {
          url: `/stock_takes`,
          method: HTTP.POST,
          body: {
            mode: (store.getState().stocktakeBarcode.mode as string) || StocktakeMode.regularStocktake,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            dispatch(resetInventoryList());
          }
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['StockTake', 'Inventories', 'Run_Out_Product', 'Inventory_Storage'],
    }),

    stopStockTake: builder.mutation<SuccessResponse, number>({
      query: (id) => {
        return {
          url: `/stock_takes/${id}/stop_stocktake`,
          method: HTTP.POST,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          if (res.data?.success) {
            dispatch(stockTakeApi.endpoints.exportStockTake.initiate(args));
          }
        } catch (e) {
          console.log(e);
        }
      },
    }),

    setCloseBalance: builder.mutation<
      SetupCloseBalanceRes,
      { id: number; close_balance: number; stocktake_id: number; invalidate?: boolean; add_more?: boolean }
    >({
      query: ({ id, ...body }) => {
        return {
          url: `/inventories/${id}/setup_close_balance`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;

        let patchResult;
        let patchResult1;
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            dispatch(setAppSuccessToast({
              title: 'Stock Added',
              message: 'Products successfully added',
            }));
          }
          patchResult = dispatch(
            inventoryApi.util.updateQueryData(
              'getInventoryStorageProducts',
              {
                name: name || undefined,
                recently_purchased,
              },
              (draft) => {
                const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
                if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.inventory_product) {
                  draft.storages[0].inventory_products[idx].balance = res?.data?.inventory_product.balance;
                  draft.storages[0].inventory_products[idx].close_balance = res?.data?.inventory_product.close_balance;
                  draft.storages[0].inventory_products[idx].stock_variance = res?.data?.inventory_product.stock_variance;
                }
              },
            ),
          );
          patchResult1 = dispatch(
            inventoryApi.util.updateQueryData('getInventoryStorages', undefined, (draft) => {
              const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
              if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.inventory_product) {
                draft.storages[0].inventory_products[idx].balance = res?.data?.inventory_product.balance;
                draft.storages[0].inventory_products[idx].close_balance = res?.data?.inventory_product.close_balance;
                draft.storages[0].inventory_products[idx].stock_variance = res?.data?.inventory_product.stock_variance;
              }
            }),
          );
        } catch {
          patchResult?.undo();
          patchResult1?.undo();
        }
      },
    }),

    addSubMeasurement: builder.mutation<AddSubMeasurementRes, { id: number; qty: number; measure: string; barcode: string }>({
      query: ({ id, qty, measure, barcode }) => {
        return {
          url: `/inventories/${id}/sub_measurements`,
          method: HTTP.POST,
          body: {
            sub_measurement: {
              unit_of_measure: measure,
              measurement_value: qty,
              barcode: barcode,
            },
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;

        let patchResult;
        let patchResult1;
        try {
          const res = await queryFulfilled;
          patchResult = dispatch(
            inventoryApi.util.updateQueryData(
              'getInventoryStorageProducts',
              {
                name: name || undefined,
                recently_purchased,
              },
              (draft) => {
                const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
                if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.sub_measurement) {
                  draft.storages[0].inventory_products[idx].sub_measurements.push(res?.data?.sub_measurement);
                }
              },
            ),
          );
          patchResult1 = dispatch(
            inventoryApi.util.updateQueryData('getInventoryStorages', undefined, (draft) => {
              const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
              if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.sub_measurement) {
                draft.storages[0].inventory_products[idx].sub_measurements.push(res?.data?.sub_measurement);
              }
            }),
          );
        } catch {
          patchResult?.undo();
          patchResult1?.undo();
        }
      },
    }),

    editSubMeasurement: builder.mutation<AddSubMeasurementRes, { id: number; qty: number; measure: string; barcode: string, sub_measurement_id: number }>({
      query: ({ id, qty, measure, barcode, sub_measurement_id }) => {
        return {
          url: `/inventories/${id}/sub_measurements/${sub_measurement_id}`,
          method: HTTP.PATCH,
          body: {
            sub_measurement: {
              unit_of_measure: measure,
              measurement_value: qty,
              barcode: barcode,
            },
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;

        let patchResult;
        let patchResult1;
        try {
          const res = await queryFulfilled;
          patchResult = dispatch(
            inventoryApi.util.updateQueryData(
              'getInventoryStorageProducts',
              {
                name: name || undefined,
                recently_purchased,
              },
              (draft) => {
                const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
                if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.sub_measurement) {
                  const subIdx = draft.storages[0].inventory_products[idx].sub_measurements.findIndex(s => s.id === res?.data?.sub_measurement.id)
                  draft.storages[0].inventory_products[idx].sub_measurements[subIdx] = res?.data?.sub_measurement;
                }
              },
            ),
          );
          patchResult1 = dispatch(
            inventoryApi.util.updateQueryData('getInventoryStorages', undefined, (draft) => {
              const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
              if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.sub_measurement) {
                const subIdx = draft.storages[0].inventory_products[idx].sub_measurements.findIndex(s => s.id === res?.data?.sub_measurement.id)
                draft.storages[0].inventory_products[idx].sub_measurements[subIdx] = res?.data?.sub_measurement;
              }
            }),
          );
        } catch {
          patchResult?.undo();
          patchResult1?.undo();
        }
      },
    }),

    deleteSubMeasurement: builder.mutation<AddSubMeasurementRes, { id: number; sub_measurement_id: number }>({
      query: ({id, sub_measurement_id}) => {
        return {
          url: `/inventories/${id}/sub_measurements/${sub_measurement_id}`,
          method: HTTP.DELETE,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const name = store.getState().inventory.selectedStorage;
        const recently_purchased = store.getState().inventory.filterBy?.recently;

        let patchResult;
        let patchResult1;
        try {
          const res = await queryFulfilled;
          patchResult = dispatch(
            inventoryApi.util.updateQueryData(
              'getInventoryStorageProducts',
              {
                name: name || undefined,
                recently_purchased,
              },
              (draft) => {
                const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
                if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.sub_measurement) {
                  const subIdx = draft.storages[0].inventory_products[idx].sub_measurements.findIndex(s => s.id === res?.data?.sub_measurement.id)
                  draft.storages[0].inventory_products[idx].sub_measurements.splice(subIdx, 1);
                }
              },
            ),
          );
          patchResult1 = dispatch(
            inventoryApi.util.updateQueryData('getInventoryStorages', undefined, (draft) => {
              const idx = draft.storages[0]?.inventory_products?.findIndex((el) => el.id === args.id);
              if (idx !== -1 && draft.storages[0]?.inventory_products[idx] && res?.data?.sub_measurement) {
                const subIdx = draft.storages[0].inventory_products[idx].sub_measurements.findIndex(s => s.id === res?.data?.sub_measurement.id)
                draft.storages[0].inventory_products[idx].sub_measurements.splice(subIdx, 1);
              }
            }),
          );
        } catch {
          patchResult?.undo();
          patchResult1?.undo();
        }
      },
    }),

    exportStockTake: builder.mutation<ExportStockTakeRes, number>({
      query: (id) => {
        return {
          url: `/stock_takes/${id}/export_stocktake`,
          method: HTTP.GET,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          if (res?.data?.success) {
            await generateStockTakeSheet(res.data);
            dispatch(setAppSuccessToast('Stock report has been exported'));
            dispatch(resetInventoryList());
          }
        } catch (e) {
          console.log(e);
        }
      },
      invalidatesTags: ['StockTake', 'Inventories', 'Run_Out_Product', 'Inventory_Storage', 'Prep_list'],
    }),
  }),

  overrideExisting: true,
});

export const {
  usePrefetch,
  useStartStockTakeMutation,
  useGetStockTakesQuery,
  useStopStockTakeMutation,
  useSetCloseBalanceMutation,
  useAddSubMeasurementMutation,
  useEditSubMeasurementMutation,
  useDeleteSubMeasurementMutation,
  useGetStockTakeHistoryQuery,
  useExportStockTakeMutation,
} = stockTakeApi;
