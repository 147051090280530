import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Close } from '@material-ui/icons';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';

import { useCreatePostCodesMutation, useExcludePostCodesMutation } from '../../../../api/company';
import { ChevronButton } from '../../../../shared/components/chevron-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { postCodeValidator, postCodeValidatorExcluded } from '../../../../shared/helpers/post-code-validator';
import { ToastService } from '../../../../shared/services/toastService';
import { MainNavBar } from '../../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';

import { useStyles } from './style';

export const AddPostCodesPage: React.FC = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { state: isExcluded } = useLocation<boolean | undefined>();

  const [postCodesToAdd, setPostCodesToAdd] = useState<string[]>([]);

  const [createPostCodes, { isLoading: createLoading }] = useCreatePostCodesMutation();
  const [excludePostCodes, { isLoading: excludeLoading }] = useExcludePostCodesMutation();

  const onSubmit = ({ code }: { code: string }, formikHelpers: FormikHelpers<any>) => {
    const invalidCodes: string[] = [];
    const validIds: string[] = [];
    setPostCodesToAdd((prev) => [
      ...prev,
      ...Array.from(
        new Set(
          code.split(' ').reduce((acc: string[], el) => {
            // just for demo only
            if(el.startsWith('#')) {
              if(!prev.includes(el)) {
                acc.push(el);
              }
              validIds.push(el);
              return acc;
            }

            const validatedCode = isExcluded ? postCodeValidatorExcluded(el) : postCodeValidator(el);
            if (!validatedCode) {
              invalidCodes.push(el.toUpperCase());
            }
            if (validatedCode && !prev.includes(validatedCode)) {
              acc.push(validatedCode);
            }
            return acc;
          }, []),
        ),
      ),
    ]);
    invalidCodes.length && ToastService.error(`Invalid postcodes: ${invalidCodes.join(', ')} can't be saved`);
    validIds.length && ToastService.success(`Customer ID added`);
    formikHelpers.setSubmitting(false);
    formikHelpers.resetForm();
  };

  const onCancel = () => {
    setPostCodesToAdd([]);
    goBack();
  };

  const onRemoveCode = (code: string) => {
    setPostCodesToAdd((prev) => prev.filter((el) => el !== code));
  };

  const onCreatePostCodes = () => {
    if (createLoading || !postCodesToAdd.length) {
      return;
    }
    createPostCodes({ postcodes: postCodesToAdd.join(',') });
    onCancel();
  };

  const onExcludePostCodes = () => {
    if (excludeLoading) {
      return;
    }
    excludePostCodes({ postcodes: postCodesToAdd.join(',') });
    onCancel();
  };

  return (
    <div className={classes.root}>
      <TopNavBar
        isAccount={true}
        bottomComponent={
          <div className={classes.bottomNavWrap}>
            <NavbarTitle title='Add Post Codes' showBackBtn={true} />
            <ChevronButton onClick={onCreatePostCodes} title='Save' className={!postCodesToAdd.length ? classes.saveBtn : ''} />
          </div>
        }
      />
      <MainNavBar isAccount={true} />
      <div className={classes.header}>Separate postcodes with a space</div>
      <Formik
        initialValues={{
          code: '',
        }}
        onSubmit={onSubmit}
      >
        {({ submitForm, resetForm }) => (
          <Form>
            <div className={classes.addBox}>
              <div className={classes.inputWrap}>
                <Field name='code'>
                  {(fieldProps: FieldProps) => (
                    <FormikInput
                      {...fieldProps}
                      label={!isExcluded ? 'New Current Post Code' : 'New Excluded Post Code'}
                      placeholder='2316'
                    />
                  )}
                </Field>
              </div>
              <div className={classes.addBoxBtns}>
                <ThemedButton
                  onClick={submitForm}
                  title='Add Postcode'
                  isSmall={true}
                  width={150}
                  bordered={true}
                  buttonStyle='primaryBordered'
                />
                <ThemedButton onClick={() => resetForm()} title='Clear' isSmall={true} width={80} buttonStyle='secondary' />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className={classes.addedCodesList}>
        {postCodesToAdd.map((code, idx) => (
          <div key={idx} className={classes.postCodeTableCell}>
            {code}
            <div className={classes.closeIconWrap} onClick={onRemoveCode.bind(null, code)}>
              <Close className={classes.closeIcon} />
            </div>
          </div>
        ))}
      </div>
      <div className={classes.addBtnBox}>
        <ThemedButton onClick={onCancel} title='Cancel' isSmall={true} buttonStyle='secondary' width={100} />
        <ThemedButton
          onClick={isExcluded ? onExcludePostCodes : onCreatePostCodes}
          title='Save'
          isSmall={true}
          width={100}
          disabled={createLoading || !postCodesToAdd.length}
        />
      </div>
    </div>
  );
};
