import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { formatCurrency } from './format-currency';
import { ExportStockTakeRes } from '../../api/stocktake/types';
import { format } from 'date-fns';
import { roundNumbers } from './roundNumbers';

export const generateStockTakeSheet = async (data: ExportStockTakeRes) => {
  const workbook = new ExcelJS.Workbook();
  workbook.created = new Date();
  workbook.modified = new Date();
  const noChangeRow = 6 + data?.data_with_variances.length;
  const subTotalWithVariances = noChangeRow - 1;
  const startFromRowsWithNoChange = 7 + data?.data_with_variances.length;
  const subTotalNoChange = startFromRowsWithNoChange + data?.data_without_variances.length;
  const grandTotal = subTotalNoChange + 1;
  const sheet = workbook.addWorksheet('Products', {
    views: [{ state: 'frozen', xSplit: 0, ySplit: 3 }],
    properties: { defaultColWidth: 20 },
  });
  sheet.getRow(1).height = 36;
  sheet.mergeCells('A1:P1');
  sheet.getRow(1).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

  sheet.getCell('A1').value = 'STOCKTAKE REPORT';
  sheet.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'efefef' },
  };
  sheet.getRow(1).font = {
    name: 'Calibri',
    size: 18,
    bold: true,
    color: {
      argb: '000000',
    },
  };

  sheet.getRow(2).height = 28;
  sheet.getRow(2).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell('A2').value = 'CREATED';
  sheet.getCell('A2').font = {
    name: 'Calibri',
    size: 12,
    color: {
      argb: '808080',
    },
  };
  sheet.getCell('B2').font = {
    name: 'Calibri',
    size: 12,
    bold: true,
    color: {
      argb: '000000',
    },
  };
  sheet.getCell('B2').value = data?.created
    ? `${format(new Date(data?.created), 'd MMMM yyyy')} | ${format(new Date(data?.created), 'HH:mm')}`
    : '';

  sheet.getCell('J2').value = 'EMPLOYEE';
  sheet.getCell('J2').font = {
    name: 'Calibri',
    size: 12,
    color: {
      argb: '808080',
    },
  };
  sheet.mergeCells('K2:L2');
  sheet.getCell('K2').value = data?.employee || '';
  sheet.getCell('K2').font = {
    name: 'Calibri',
    size: 12,
    bold: true,
    color: {
      argb: '000000',
    },
  };

  sheet.getRow(3).height = 28;
  sheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getRow(3).font = {
    name: 'Calibri',
    size: 11,
    bold: true,
    color: {
      argb: '000000',
    },
  };
  sheet.getRow(3).border = {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } },
  };

  data?.headers.forEach((header, idx) => {
    sheet.getCell(2, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' },
    };
    sheet.getCell(3, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' },
    };
    sheet.getCell(4, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' },
    };
    sheet.getCell(noChangeRow, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' },
    };
    sheet.getCell(subTotalWithVariances, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'dadada' },
    };
    sheet.getCell(subTotalNoChange, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'dadada' },
    };
    sheet.getCell(grandTotal, idx + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'dadada' },
    };
    sheet.getCell(3, idx + 1).value = header.label;
    sheet.getCell(3, idx + 1);
  });

  sheet.getColumn('A').width = 15;
  sheet.getColumn('B').width = 40;
  sheet.getColumn('D').width = 13;
  sheet.getColumn('E').width = 12;
  sheet.getColumn('F').width = 25;
  sheet.getColumn('H').width = 12;
  sheet.getColumn('I').width = 12;
  sheet.getColumn('J').width = 13;
  sheet.getColumn('K').width = 13;
  sheet.getColumn('L').width = 12;
  sheet.getColumn('M').width = 12;

  sheet.getRow(4).height = 20;
  sheet.getRow(4).border = {
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getCell('A4').font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };
  sheet.getCell('A4').value = 'WITH VARIANCES';
  sheet.getCell('A4').alignment = { vertical: 'middle', horizontal: 'left' };
  sheet.getCell('L4').value = data?.data_with_variances.length;
  sheet.getCell('L4').alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell('L4').font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };

  const generateCells = (rows: any[], startFrom: number) => {
    rows.forEach((row, rowIdx) => {
      data?.headers.forEach((header, colIdx) => {
        if(header.key === 'Price') {
          sheet.getCell(rowIdx + startFrom, colIdx + 1).value = formatCurrency(row[header.key]);
        } else if(colIdx === 15) {
          sheet.getCell(rowIdx + startFrom, colIdx + 1).value = `${row[header.key]}%`;
        } else if(colIdx === 10 || colIdx === 11) {
          sheet.getCell(rowIdx + startFrom, colIdx + 1).value = roundNumbers(row[header.key]);
        } else if(header.key === 'Opening Value' || header.key === 'Closing Value' || header.key === 'Change in Value') {
          const val = parseFloat(row[header.key]);
          sheet.getCell(rowIdx + startFrom, colIdx + 1).value = val === 0 ? '-' : formatCurrency(row[header.key]);
        } else {
          sheet.getCell(rowIdx + startFrom, colIdx + 1).value = row[header.key];
        }
        switch (colIdx) {
          case 0: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
              color: {
                argb: '808080',
              },
            };
            break;
          }
          case 1: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).alignment = { wrapText: true };
            break;
          }
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
              color: {
                argb: '434343',
              },
            };
            if (colIdx === 4) {
              sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
                color: {
                  argb: '000000',
                },
              };
            }
            break;
          }
          case 10:
          case 11: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
              bold: true,
            };
            break;
          }
          case 12:
          case 13:
          case 14: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
              bold: true,
            };
            break;
          }
          case 15: {
            sheet.getCell(rowIdx + startFrom, colIdx + 1).font = {
              color: {
                argb: row[header.key] > 0 ? '38761d' : row[header.key] < 0 ? 'cc0000' : 'b7b7b7',
              },
            };
            sheet.getCell(rowIdx + startFrom, colIdx + 1).alignment = { vertical: 'middle', horizontal: 'center' };
            break;
          }

          default: {
          }
        }
      });
    });
  };

  generateCells(data?.data_with_variances || [], 5);

  const getSumValues = (rows: any[], name: string) => {
    return rows.reduce((sum, item) => {
      return sum + (parseFloat(item[name]) || 0);
    }, 0);
  }

  sheet.getRow(subTotalWithVariances).height = 20;
  sheet.getRow(subTotalWithVariances).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getCell(`A${subTotalWithVariances}`).font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };
  sheet.getCell(`A${subTotalWithVariances}`).value = 'SUB TOTAL (WITH VARIANCES)';
  sheet.getCell(`A${subTotalWithVariances}`).alignment = { vertical: 'middle', horizontal: 'left' };
  const openingValueWithVariances = getSumValues(data?.data_with_variances || [], 'Opening Value');
  sheet.getCell(`M${subTotalWithVariances}`).value = openingValueWithVariances === 0 ? '-' : formatCurrency(openingValueWithVariances);
  sheet.getCell(`M${subTotalWithVariances}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`M${subTotalWithVariances}`).font = {
    bold: true,
  };
  const closingValueWithVariances = getSumValues(data?.data_with_variances || [], 'Closing Value');
  sheet.getCell(`N${subTotalWithVariances}`).value = closingValueWithVariances === 0 ? '-' : formatCurrency(closingValueWithVariances);
  sheet.getCell(`N${subTotalWithVariances}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`N${subTotalWithVariances}`).font = {
    bold: true,
  };
  const changInValueWithVariances = getSumValues(data?.data_with_variances || [], 'Change in Value');
  sheet.getCell(`O${subTotalWithVariances}`).value = changInValueWithVariances === 0 ? '-' : formatCurrency(changInValueWithVariances);
  sheet.getCell(`O${subTotalWithVariances}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`O${subTotalWithVariances}`).font = {
    bold: true,
  };
  const changeWithVariances = roundNumbers((changInValueWithVariances / openingValueWithVariances)*100);
  sheet.getCell(`P${subTotalWithVariances}`).value = `${changeWithVariances}%`;
  sheet.getCell(`P${subTotalWithVariances}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`P${subTotalWithVariances}`).font = {
    bold: true,
    color: {
      argb: changeWithVariances > 0 ? '38761d' : changeWithVariances < 0 ? 'cc0000' : 'b7b7b7',
    },
  };

  sheet.getRow(noChangeRow).height = 20;
  sheet.getRow(noChangeRow).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getCell(`A${noChangeRow}`).font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };
  sheet.getCell(`A${noChangeRow}`).value = 'WITH NO CHANGE';
  sheet.getCell(`A${noChangeRow}`).alignment = { vertical: 'middle', horizontal: 'left' };
  sheet.getCell(`L${noChangeRow}`).value = data?.data_without_variances.length;
  sheet.getCell(`L${noChangeRow}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`L${noChangeRow}`).font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };

  generateCells(data?.data_without_variances || [], startFromRowsWithNoChange);

  sheet.getRow(subTotalNoChange).height = 20;
  sheet.getRow(subTotalNoChange).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getCell(`A${subTotalNoChange}`).font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };
  sheet.getCell(`A${subTotalNoChange}`).value = 'SUB TOTAL (NO CHANGE)';
  sheet.getCell(`A${subTotalNoChange}`).alignment = { vertical: 'middle', horizontal: 'left' };
  const openingValueNoChange = getSumValues(data?.data_without_variances || [], 'Opening Value');
  sheet.getCell(`M${subTotalNoChange}`).value = openingValueNoChange === 0 ? '-' : formatCurrency(openingValueNoChange);
  sheet.getCell(`M${subTotalNoChange}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`M${subTotalNoChange}`).font = {
    bold: true,
  };
  const closingValueNoChange = getSumValues(data?.data_without_variances || [], 'Closing Value');
  sheet.getCell(`N${subTotalNoChange}`).value = closingValueNoChange === 0 ? '-' : formatCurrency(closingValueNoChange);
  sheet.getCell(`N${subTotalNoChange}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`N${subTotalNoChange}`).font = {
    bold: true,
  };
  const changeInValueNoChange = getSumValues(data?.data_without_variances || [], 'Change in Value');
  sheet.getCell(`O${subTotalNoChange}`).value = changeInValueNoChange === 0 ? '-' : formatCurrency(changeInValueNoChange);
  sheet.getCell(`O${subTotalNoChange}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`O${subTotalNoChange}`).font = {
    bold: true,
  };
  sheet.getCell(`P${subTotalNoChange}`).value = '0.0%';
  sheet.getCell(`P${subTotalNoChange}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`P${subTotalNoChange}`).font = {
    bold: true,
    color: {
      argb: 'b7b7b7',
    },
  };

  
  sheet.getRow(grandTotal).height = 20;
  sheet.getRow(grandTotal).border = {
    top: { style: 'thin', color: { argb: '969696' } },
    left: { style: 'thin', color: { argb: '969696' } },
    bottom: { style: 'thin', color: { argb: '969696' } },
    right: { style: 'thin', color: { argb: '969696' } },
  };
  sheet.getCell(`A${grandTotal}`).font = {
    name: 'Arial',
    size: 9,
    color: {
      argb: '0000ff',
    },
  };
  sheet.getCell(`A${grandTotal}`).value = 'GRAND TOTAL';
  sheet.getCell(`A${grandTotal}`).alignment = { vertical: 'middle', horizontal: 'left' };
  
  sheet.getCell(`M${grandTotal}`).value = formatCurrency(openingValueWithVariances + openingValueNoChange);
  sheet.getCell(`M${grandTotal}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`M${grandTotal}`).font = {
    bold: true,
  };
  sheet.getCell(`N${grandTotal}`).value = formatCurrency(closingValueWithVariances + closingValueNoChange);
  sheet.getCell(`N${grandTotal}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`N${grandTotal}`).font = {
    bold: true,
  };
  sheet.getCell(`O${grandTotal}`).value = formatCurrency(changInValueWithVariances);
  sheet.getCell(`O${grandTotal}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`O${grandTotal}`).font = {
    bold: true,
  };
  const changeGrandTotal = roundNumbers((changInValueWithVariances / (openingValueWithVariances + openingValueNoChange))*100);
  sheet.getCell(`P${grandTotal}`).value = `${changeGrandTotal}%`;
  sheet.getCell(`P${grandTotal}`).alignment = { vertical: 'middle', horizontal: 'center' };
  sheet.getCell(`P${grandTotal}`).font = {
    bold: true,
    color: {
      argb: changeGrandTotal > 0 ? '38761d' : changeWithVariances < 0 ? 'cc0000' : 'b7b7b7',
    },
  };

  const bufferRes = await workbook.xlsx.writeBuffer();
  const blob = new Blob([bufferRes], { type: 'application/xlsx' });
  await saveAs(blob, `StockTake_${data.created}.xlsx`);
  return Promise.resolve();
};
