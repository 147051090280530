import { createAction } from '@reduxjs/toolkit';
import { InventoryFilter, InventorySortBy } from '../../api/inventory/types';

export const setInventoryKeyword = createAction<string>('SET_INVENTORY_KEYWORD');

export const setInventoryStorageKeyword = createAction<string>('SET_INVENTORY_STORAGE_KEYWORD');

export const setInventoryPage = createAction<number>('SET_INVENTORY_PAGE');

export const resetInventoryList = createAction('RESET_INVENTORY_LIST');

export const setReplenishLoading = createAction<boolean>('SET_REPLENISH_LOADING');

export const setSelectedStorage = createAction<string | null>('SET_SELECTED_STORAGE');

export const setSelectedInventorySupplier = createAction<number | null>('SET_SELECTED_INVENTORY_SUPPLIER');

export const setSelectedInventoryStorageSupplier = createAction<number | null>('SET_SELECTED_INVENTORY_STORAGE_SUPPLIER');

export const setInventoryFilter = createAction<InventoryFilter | undefined>('SET_INVENTORY_FILTER');

export const setInventorySortFilter = createAction<{ filter?: InventoryFilter; sort?: InventorySortBy }>('SET_INVENTORY_SORT_FILTER');

export const setInventorySort = createAction<InventorySortBy | undefined>('SET_INVENTORY_SORT');

export const setAddSubMeasureFor = createAction<number | null>('SET_ADD_SUB_MEAUSURE_FOR');

export const setEditSubMeasureFor = createAction<{id: number | null, sub_measure_id: number}>('SET_EDIT_SUB_MEAUSURE_FOR');

export const setEditModeInventoryRow = createAction<number | null>('SET_EDIT_MODE_INVENTORY_ROW');
