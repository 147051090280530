import { createAction } from '@reduxjs/toolkit';
import { SSInventorySortBy } from '../../api/supplier-inventory/types';

export const setSupplierInventoryKeyword = createAction<string>('SET_SUPPLIER_INVENTORY_KEYWORD');

export const setSupplierInventorySort = createAction<SSInventorySortBy | undefined>('SET_SUPPLIER_INVENTORY_SORT');

export const setSupplierInventorySubCategory = createAction<number | undefined>('SET_SUPPLIER_INVENTORY_SUB_CATEGORY');

export const setSupplierInventoryPage = createAction<number>('SET_SUPPLIER_INVENTORY_PAGE');
