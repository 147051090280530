import { createReducer } from '@reduxjs/toolkit';
import { setHistoryStocktakePage } from './history-stocktake.actions';

interface State {
  page: number;
}

const initialState: State = {
  page: 1,
};

export const historyStocktakeReducer = createReducer(initialState, (builder) => {
  builder.addCase(setHistoryStocktakePage, (state, { payload }) => {
    state.page = payload;
  });
});
