import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    // tablet or mobile
    root: {
      backgroundColor: colorVariables.white,
      position: 'absolute',
      zIndex: 110,
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 360,
      right: 0,
      [theme.breakpoints.down(Sizes.desktop)]: {
        transition: 'right ease-in-out 0.5s',
      },
      [theme.breakpoints.down(Sizes.mobile)]: { width: 'calc(100vw - 30px)' },
    },
    rootClose: {
      transition: 'right ease-in-out 0.5s',
      [theme.breakpoints.down(Sizes.mobile)]: { width: 'calc(100vw - 30px)' },
      right: '-120vw',
    },

    iconButton: {
      padding: 5,
    },

    // desktop:
    searchWrap: {
      width: '100%',
      '&:before': {
        borderColor: colorVariables.grey40,
      },
      '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderColor: colorVariables.grey40,
      },
    },

    searchIcon: {
      color: colorVariables.grey60,
      marginBottom: 5,
    },
    searchInput: {
      border: 'none',
      fontSize: 15,
      paddingBottom: 10,
      padding: '5px 10px',
      width: '100%',
      opacity: 0.6,
    },
  }),
);
