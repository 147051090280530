import { PaginationResult } from '../index';
import { SupplierUser } from '../user/types';

export interface GetSupplierListParams {
  lat?: number;
  lng?: number;
  keyword?: string;
  sort_by?: string;
  category_id?: number;
  all_supplier?: boolean;
  pending_supplier?: boolean;
  page?: number;
  per_page?: number;
  has_inventory?: boolean;
}

export interface CreateSupplierRequest {
  company: {
    name: string;
    suppliers_attributes: [
      {
        first_name: string;
        email: string;
        phone_number?: string;
      },
    ];
    company_categories_attributes?: [
      {
        category_id: number;
      },
    ];
  };
}

export interface SupplierListResponse extends PaginationResult {
  suppliers: SupplierUser[];
}

export enum ManualSupplierAction {
  UPLOAD,
  VIEW,
  EDIT,
}
